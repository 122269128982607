<div fxLayout="column" fxFlexFill>

<app-header></app-header>
  <div fxFlex (click)="disableContextMenu()">
    <router-outlet></router-outlet>
  </div>
  <!-- Brute force padding at bottom, so you do not have to worry about the fixed footer overlapping bottom content.-->
  <div style="padding-bottom: 2%"></div>
  <app-footer class="footer"></app-footer>
</div>
