import {Component} from '@angular/core';
import {ContextMenuService, DataType, Menu} from '../services/context-menu.service';
import {ExportService} from '../services/export.service';
import {CommonMessagesService} from '../services/common-messages.service';

@Component({
  selector: 'app-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.css'],
})
// Note it will always close itself on a left click due to how its coded with the
// "any left click set show to false in top component", but the (click) logic applied here will  still run.
export class ContextMenuComponent {
  // Coordinates on mouse click where the menu should spawn.
  menu: Menu;
  constructor(private contextMenuService: ContextMenuService,
              private exportService: ExportService,
              private ms: CommonMessagesService) {
    contextMenuService.getContextMenuSub().subscribe(menu => this.menu = menu);
  }
  export() {
    if (this.menu.type !== DataType.Reason) {
      this.ms.displayError('Uhåndtert data type for exportering!');
      return;
    }
    this.exportService.exportReasonToExcel(this.menu.contextData);
  }
  // This is a workaround to accessing the DataType enum in the service, as it is outside of the scope for ngIf.
  typeCheck(n: number): boolean {
    switch (n) {
      case 0:
        return this.menu.type === DataType.Reason;
      case 1:
        return this.menu.type === DataType.None;
      default:
        throw new Error(('Unhandled typecheck number: ' + n));
    }
  }
}
