import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {AvioComService} from '../../services/avio-com.service';
import {AuthService} from '../../services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(private router: Router, public auth: AuthService) { }

  ngOnInit(): void {
  }

  goToBudget() {
    // budget/1596505? Maybe oslo instead.
    this.router.navigateByUrl('/budgetReport');
  }

  goToFo() {
    // foReport/1596505
    this.router.navigateByUrl('/foReport');
  }
  // Some unused code for finding out which one is 'active'. Does return true if you give it 'foReport'.
  isActive(s: string){
    let url = this.router.url;
    url = url.split('/')[1];
    return url === s;
  }

  logout() {
    this.auth.logout();
    this.router.navigateByUrl('login');
  }
  login() {
    this.router.navigateByUrl('login');
  }

  goToFaq() {
    this.router.navigateByUrl('faq');
  }
  public get authenticated(): boolean {
    return this.auth.isAuthenticated();
  }
}
