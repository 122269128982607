export class Transaction {
  date: string;
  module: string;
  batchNumber: string;
  period: string;
  description: string;
  refNumber: string;
  branch: string;
  account: VismaAccount;
  subAccount: string;
  begBalance: string;
  debitAmount: string;
  creditAmount: string;
  endingBalance: string;
  currency: string;
  currBegBalance: string;
  currDebitAmount: string;
  currCreditAmount: string;
  currEndingBalance: string;
  lastModified: string;
  // For display
  reasonNumber: string;

  constructor(date: string, module: string, batchNumber: string, period: string, description: string, refNumber: string, branch: string,
              account: VismaAccount, subAccount: string, begBalance: string, debitAmount: string, creditAmount: string,
              endingBalance: string, currency: string, currBegBalance: string, currDebitAmount: string, currCreditAmount: string,
              currEndingBalance: string, lastModified: string) {
    this.date = date;
    this.module = module;
    this.batchNumber = batchNumber;
    this.period = period;
    this.description = description;
    this.refNumber = refNumber;
    this.branch = branch;
    this.account = account;
    this.subAccount = subAccount;
    this.begBalance = begBalance;
    this.debitAmount = debitAmount;
    this.creditAmount = creditAmount;
    this.endingBalance = endingBalance;
    this.currency = currency;
    this.currBegBalance = currBegBalance;
    this.currDebitAmount = currDebitAmount;
    this.currCreditAmount = currCreditAmount;
    this.currEndingBalance = currEndingBalance;
    this.lastModified = lastModified;
    this.getReasonNumber();
  }
  getReasonNumber(): void {
    this.reasonNumber = this.subAccount.substring(2, 5);
  }
}

export class VismaAccount {
  type: string;
  accNumber: string;
  description: string;

  constructor(type: string, accNumber: string, description: string) {
    this.type = type;
    this.accNumber = accNumber;
    this.description = description;
  }
}
