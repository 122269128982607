<div *ngIf="!hideAll">
  <form [formGroup]="tableForm">
    <p>{{branch}}</p>
    <table mat-table #table [dataSource]="dataSource" class="mat-elevation-z8">
      <ng-container matColumnDef="account">
        <mat-header-cell *matHeaderCellDef> Formål </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let row let i = index">
          <ng-container [formGroup]="row">
            <input matInput type="text" id="konto_{{i}}" name="konto_{{i}}" formControlName="account" readonly>
          </ng-container>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef> </mat-footer-cell>
      </ng-container>
      <ng-container matColumnDef="name">
        <mat-header-cell mat-header-cell *matHeaderCellDef> Navn </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let row let i = index">
          <ng-container [formGroup]="row">
            <input matInput type="text" id="navn_{{i}}" name="navn_{{i}}" formControlName="name" readonly>
          </ng-container>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef> SUM: </mat-footer-cell>
      </ng-container>
      <ng-container matColumnDef="budget">
        <mat-header-cell *matHeaderCellDef> budsjett </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let row let i = index">
          <ng-container [formGroup]="row">
            <input matInput type="number" id="budsjett_{{i}}" name="budsjett_{{i}}" (change)="overRideBudget(i, $event, true)" formControlName="budget">
          </ng-container>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef> {{this.sumBudget | number: this.digitDecString: 'no'}}</mat-footer-cell>
      </ng-container>
      <ng-container matColumnDef="revisedBudget">
        <mat-header-cell *matHeaderCellDef> Revidert Budsjett </mat-header-cell>
        <mat-cell *matCellDef="let row let i = index">
          <ng-container [formGroup]="row">
           <input matInput type="number" id="revidertBudsjett_{{i}}" name="revidertBudsjett_{{i}}" formControlName="rBudget" (change)="overRideBudget(i, $event, false)">
          </ng-container>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef> {{this.sumRevisedBudget | number: this.digitDecString: 'no'}}</mat-footer-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row >
      <mat-footer-row *matFooterRowDef="displayedColumns"></mat-footer-row>
    </table>
    <mat-error *ngIf="tableForm.get(this.branchNumber).invalid && tableForm.get(this.branchNumber).dirty">*Feil format funnet her. Vennligst sjekk at bare tall er brukt.</mat-error>
  </form>
</div>
