<div fxLayout="column" fxLayoutAlign="center center">
  <mat-accordion style="margin-top: 15px"  class="faq">
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Hvor ser jeg vilken avdeling som det rapporteres for?
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <p>Øverst til høyere vil det stå hvilken region som er i bruk som f.eks Oslo, eller Fellesorganisasjonen (Fo) når hoved kontoen er brukt.
        Hvis du isteden ser Fo Rapporter betyr det at du trenger å logge inn.</p>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Hvorfor tar den for lang tid til å laste ned rapporten?
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <p>Våres system må stille mange spørsmål til visma.net financials for å hente all dataen som er nødvendig til rapportene. Dette kan desverre lede til lengre genererings tider.
      Hvis den har stått fast lenge, så sjekk at du faktisk har tilgang til internett, og du kan evt vurdere å laste inn siden på nytt for å prøve igjen. For formåls rapporten går det raskere jo færre år/perioder det filtreres etter.</p>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Hvorfor ble jeg logget ut?
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <p>Per nå er det satt at man logges ut autmatisk når enn siden enten blir lukket, eller om man trykker på Last inn siden på nytt.</p>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Hva gjør skjul formål med 0 instillingen?
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <p>Skjul formål med 0 når satt på, vil gjemme ifra listen som vises alle formål som har en nåværende kostnad på 0.
      Vennligst kontakt kundesentere hvis noen formål med 0 i kostnad vises da denne er slått på.</p>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Jeg finner ingen budsjetter.
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <p>Vennligst bekreft at det faktisk er budsjetter I visma.net financials for din nåværene bruker. Sjekk også at du har internett tilgang.
        Hvis den har budsjetter, og disse ikke blir funnet så vennligst kontakt kundesentere. Husk å inkludere feil meldinger hvis de kommer opp.</p>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Budsjetter blir ikke lastet opp i det hele tatt?
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <p>Vennligst bekreft at du har en internett tilkobling, og se etter feilmeldinger under budsjett opplastning som kan gi mer informasjon.</p>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Budsjetter blir lastet opp, men den har feil?
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <p>Vennligst bekreft via visma.net financials hva nåværende budsjett er registret som, og juster om nødvendig der.
        Hvis visma.net financials har riktig data så må du kontakte kundesentere for å unnga denne feilen igjen. Husk å inkludere feil meldinger hvis en kommer opp.</p>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Hvordan tilbakestiller jeg brukernavn eller passord?
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <p>Per nå er det ikke mulig å forandre verken brukernavn eller passord. Du må derfor evt kontakte kundesentere for å få gjort dette.</p>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Hvordan eksporterer jeg transaksjoner?
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <p>Høyre klikk på en av transaksjons linjenen som kommer opp da man trykker på formål, for så å trykke på "eksporter"</p>
    </mat-expansion-panel>
    <!-- Uncomment this if you are using the buffer as a menu option.
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Når burde jeg skru av buffer?
      </mat-panel-title>
      <mat-panel-description>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <p>Buffer burde alltid brukes, siden formåls rapport vil ta veldig lang tid uten. Hoved grunnen til å skru av buffer er f.eks å konfirmere at alle transaksjoner faktisk går igjennom riktig.</p>
  </mat-expansion-panel>
    -->
  </mat-accordion>
</div>
