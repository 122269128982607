import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BudgetFormValues} from '../../models/budgetModels/BudgetFormValues';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Context} from '../../models/Context';
import {AvioComService} from '../../services/avio-com.service';

@Component({
  selector: 'app-budget-menu',
  templateUrl: './budget-menu.component.html',
  styleUrls: ['./budget-menu.component.css']
})
export class BudgetMenuComponent implements OnInit {
  form: FormGroup;
  @Output() formValues: EventEmitter<BudgetFormValues> = new EventEmitter<BudgetFormValues>();
  hide: boolean;

  constructor(private as: AvioComService,
              private fb: FormBuilder) { }

  ngOnInit(): void {
    // this.downloadContexts();
    this.form = this.fb.group({
      // style is:
      // formControllName: ["initial value", ["Validation needded for it to be "valid"],
      // Must be used.       year from 1000 to 2999.
      year: [this.getCurrentYear(), [Validators.required, Validators.pattern('^[12][0-9]{3}$'),
        // Double check length
        Validators.maxLength(4), Validators.minLength(4)]],
      ledger: ['2', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(1), Validators.maxLength(2)]],
      revisedLedger: ['5', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(1), Validators.maxLength(2)]]
    });
    this.hide = true;
  }

  contextComparator(c1: Context, c2: Context): boolean{
    return c1.id === c2.id;
  }
  get year(){return this.form.get('year'); }
  get ledger(){return this.form.get('ledger'); }
  get revisedLedger(){return this.form.get('revisedLedger'); }
  getCurrentYear() {
    return this.as.getCurrentYear();
  }
  onSubmit() {
    const budgetFormValues = new BudgetFormValues(this.form);
    this.formValues.emit(budgetFormValues);
  }

}
