<div *ngIf="!hideAll && !this.hideAll0s" style="width: 99%">
  <p>{{branch}}</p>
  <mat-table [dataSource]="dataSource" class="mat-elevation-z8" multiTemplateDataRows>
    This sometimes leads to some tables looking "slightly" off compared to the one above etc.-->
    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef> Formål </mat-header-cell>
      <mat-cell *matCellDef="let reason"> {{reason.reasonNumber}}</mat-cell>
      <mat-footer-cell *matFooterCellDef> </mat-footer-cell>
    </ng-container>
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef> Navn </mat-header-cell>
      <mat-cell *matCellDef="let reason"> {{reason.name}} </mat-cell>
      <mat-footer-cell *matFooterCellDef> SUM: </mat-footer-cell>
    </ng-container>
    <ng-container matColumnDef="costs">
      <mat-header-cell *matHeaderCellDef> Kostnader </mat-header-cell>
      <mat-cell *matCellDef="let reason"> {{reason.costs | number: this.digitDecString: 'no'}} </mat-cell>
      <mat-footer-cell *matFooterCellDef> {{this.sumCosts | number: this.digitDecString: 'no'}}</mat-footer-cell>
    </ng-container>
    <ng-container matColumnDef="budget">
      <mat-header-cell *matHeaderCellDef> Budsjett </mat-header-cell>
      <mat-cell *matCellDef="let reason"> {{reason.budget | number: this.digitDecString: 'no'}}  </mat-cell>
      <mat-footer-cell *matFooterCellDef> {{this.sumBudget | number: this.digitDecString: 'no'}}</mat-footer-cell>
    </ng-container>
    <ng-container matColumnDef="inPOfBudget">
      <mat-header-cell *matHeaderCellDef> I % av budsjett </mat-header-cell>
      <mat-cell *matCellDef="let reason"> {{reason.inPOfBudget.toFixed(2)}} </mat-cell>
      <mat-footer-cell *matFooterCellDef> {{this.sumPOfBudget.toFixed(2)}}</mat-footer-cell>
    </ng-container>
    <ng-container matColumnDef="revisedBudget">
      <mat-header-cell *matHeaderCellDef> Revidert Budsjett </mat-header-cell>
      <mat-cell *matCellDef="let reason"> {{reason.revisedBudget | number: this.digitDecString: 'no'}}  </mat-cell>
      <mat-footer-cell *matFooterCellDef> {{this.sumRevisedBudget| number: this.digitDecString: 'no'}}</mat-footer-cell>
    </ng-container>
    <ng-container matColumnDef="inPOfRevisedBudget">
      <mat-header-cell  *matHeaderCellDef> I % av revidert Budsjett </mat-header-cell >
      <mat-cell *matCellDef="let reason"> {{reason.inPOfRevisedBudget.toFixed(2)}} </mat-cell>
      <mat-footer-cell *matFooterCellDef> {{this.sumPOfRevisedBudget.toFixed(2)}}</mat-footer-cell>
    </ng-container>
    <ng-container matColumnDef="expandedDetail">
      <!-- Extra details on click here-->
      <mat-cell *matCellDef="let reason" style="justify-content: center;">
        <table (contextmenu)="onRightClick($event, reason)">
          <tr>
            <th style="text-align: left">Ref:</th>
            <th style="float:left">Description:</th>
            <th style="text-align: right;">Debit Amount:</th>
            <th style="text-align: right;">Credit Amount:</th>
          </tr>
          <tr *ngFor="let t of reason.transactions" class="table-divider" >
            <td style="text-align: left">{{t.refNumber}}</td>
            <td style="float:left">{{t.description}}</td>
            <td style="text-align: right;">{{t.debitAmount.toFixed(decimals) | number: '': 'no'}}</td>
            <td style="text-align: right;">{{t.creditAmount.toFixed(decimals) | number: '': 'no'}}</td>
          </tr>
          <tr style="background-color: #f3f3f3">
            <td style="text-align: left"></td>
            <td style="float:right">Sum: {{(reason.sumDebitAmount - reason.sumCreditAmount).toFixed(decimals) | number: '': 'no'}}</td>
            <td style="text-align: right;">{{reason.sumDebitAmount.toFixed(decimals) | number: '': 'no'}}</td>
            <td style="text-align: right;">{{reason.sumCreditAmount.toFixed(decimals) | number: '': 'no'}}</td>
          </tr>
        </table>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"
             matRipple
             class = "element-row"
             [class.expanded]="expandedElement === row"
             (click)="setExpanded(row)"></mat-row>
    <mat-row *matRowDef="let row; columns: ['expandedDetail']; when: isExpansionDetailRow"
             [@detailExpand]="row == expandedElement ? 'expanded' : 'collapsed'"
             style="overflow: hidden">
    </mat-row>
    <mat-footer-row *matFooterRowDef="displayedColumns"></mat-footer-row>
  </mat-table>
</div>
