import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
import {Reason} from '../models/Reason';
import {Transaction} from '../models/Transaction';
import {CommonMessagesService} from './common-messages.service';
import {Branch} from '../models/Branch';
@Injectable({
  providedIn: 'root'
})
export class ExportService {

  constructor( private ms: CommonMessagesService) { }
  jsonStringToExcel(json: any[], fileName: string, sheetName: string, type: number): string {
    try{
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
      if (type === 0) {
        // Transactions
        // sets width of the columns.
        worksheet['!cols'] = [
          {wch: 10},
          {wch: 15},
          {wch: 15},
          {wch: 10},
          {wch: 40},
          {wch: 20},
        ];
      } else if (type === 1) {
        //  Branches
        worksheet['!cols'] = [
          {wch: 15},
          {wch: 30},
          {wch: 15},
          {wch: 15},
          {wch: 15},
          {wch: 15},
          {wch: 15},
        ];
      }
      const workbook: XLSX.WorkBook = { Sheets: { [sheetName]: worksheet }, SheetNames: [sheetName] };
      XLSX.writeFile(workbook, fileName);
      return 'OK';
    } catch (error) {
      console.error(error);
      this.ms.displayErrorFromError(error);
    }
  }

  exportReasonToExcel(r: Reason) {
    const ts = r.transactions;
    // Kept separate for simplicity, might want to redo these anyway at some point.
    // Attempt to remove certain stuff for filename errors..
    let fName = r.name.replace('[\\\\/:"*?<>|]+', '');
    // Attempt to clean out spaces
    fName = fName.replace('/\s/g', '_');
    // Attempt to clean out "-" to make the others create cleaner fileNames.
    fName = fName.replace('-', '');
    // Attempt to clean out "double "__"
    fName = fName.replace('(?=__)', '_');
    const json = this.convertTransactionsToJSON(ts);
    const fileName = r.name.replace(' ', '_') + '' + new Date().toISOString() + '.xlsx';
    this.jsonStringToExcel(json, fileName, 'FO_webreport_linjer', 0);
  }
  convertTransactionsToJSON(ts: Transaction[]) {
    const trans = [];
    let totDebit = 0.0;
    let totCredit = 0.0;
    for (const t of ts) {
      totDebit += Number(t.debitAmount);
      totCredit += Number(t.creditAmount);
      const sum = Number(t.creditAmount) + Number(t.debitAmount);
      // Converts dates to how it was in old report.
      const dateS = t.date.split('T')[0].split('-');
      const date = dateS[2] + '.' + dateS[1] + '.' + dateS[0];
      const acc = t.account.accNumber;
      trans.push({Formål: t.reasonNumber, 'Bilagsnr (RefNr)': t.refNumber, bilagsDato: date,
        Konto: acc, Tekst: t.description, Sum: sum});
    }
    trans.push({Formål: '', 'Bilagsnr (RefNr)': '', bilagsDato: '',
      Konto: '', Tekst: 'SUM:', Sum: totCredit + totDebit});
    return JSON.parse(JSON.stringify(trans));
  }
  convertBranchesToJSON(bs: Branch[]) {
    // avd block
    let totCost = 0.0;
    let totBudget = 0.0;
    // TODO calculate Ps.
    const totPBudg = 0.0;
    let totRBudget = 0.0;
    const totPRBudget = 0.0;
    const avd = [];
    const fos: Reason[] = [];
    for (const b of bs) {
      // @ts-ignore
      fos.push(b.reasons);
      totCost += b.totCost;
      totBudget += b.totBudget;
      totRBudget += b.totRevisedBudget;
      avd.push({'Avd.': b.account, Tekst: b.name, Kostnader: b.totCost, Budsjett: b.totBudget, 'I % av budsjett': b.inPOfTotalBudget,
        'Revidert budsjett': b.totRevisedBudget, 'I % av revidert budsjett': b.inPOfTotalRevisedBudget});
    }
    avd.push({Tekst: 'SUM', Kostnader: totCost, Budsjett: totBudget, 'I % av budsjett': totPBudg,
      'Revidert budsjett': totRBudget, 'I % av revidert budsjett': totPRBudget});
    for (const b of bs) {
      avd.push({});
      avd.push({'Avd.': b.account, Tekst: b.name});
      avd.push({'Avd.': 'Formål', Tekst: 'Tekst', Kostnader: 'Kostnader', Budsjett: 'Budsjett', 'I % av budsjett': 'I % av budsjett',
        'Revidert budsjett': 'Revidert budsjett', 'I % av revidert budsjett': 'I % av revidert budsjett'});
      let Cost = 0.0;
      let Budget = 0.0;
      const PBudg = 0.0;
      let RBudget = 0.0;
      const PRBudget = 0.0;
      for (const r of b.reasons) {
        Cost += r.costs;
        Budget += r.budget;
        RBudget += r.revisedBudget;
        avd.push({'Avd.': r.reasonNumber, Tekst: r.name, Kostnader: r.costs, Budsjett: r.budget, 'I % av budsjett': r.inPOfBudget,
          'Revidert budsjett': r.revisedBudget, 'I % av revidert budsjett': r.inPOfRevisedBudget});
      }
      avd.push({Tekst: 'SUM', Kostnader: Cost, Budsjett: Budget, 'I % av budsjett': PBudg,
        'Revidert budsjett': RBudget, 'I % av revidert budsjett': PRBudget});
    }
    // formål block
    return JSON.parse(JSON.stringify(avd));
  }
  exportFoRepportToExcel(bs: Branch[]) {
    // Kept separate for simplicity, might want to redo these anyway at some point.
    // Attempt to remove certain stuff for filename errors..
    const json = this.convertBranchesToJSON(bs);
    const fileName = 'FoRapportEksport' + new Date().toISOString() + '.xlsx';
    this.jsonStringToExcel(json, fileName, 'FO_webreport', 1);
  }
}
