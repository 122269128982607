import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Context} from '../../../../models/Context';
import {AvioComService} from '../../../../services/avio-com.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FoFormValues} from '../../../../models/FoFormValues';
import {Router} from '@angular/router';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';

@Component({
  selector: 'app-menu',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  form: FormGroup;
  selectedContext: Context;
  periods: string[];
  hide: boolean;
  forceAll: boolean;
  @Output() formValues: EventEmitter<FoFormValues> = new EventEmitter<FoFormValues>();
  // TODO bind a behsubject of loading, and apply to button.
  constructor(private as: AvioComService,
              private fb: FormBuilder) { }

  ngOnInit(): void {
    this.periods = this.as.getPeriods();
    // this is an object representing the form that will be "initialised, validated, then submited".
    this.form = this.fb.group({
      // style is:
      // formControllName: ["initial value", ["Validation needded for it to be "valid"],
                                  // Must be used.       year from 1000 to 2999.
      fromYear: [this.getCurrentYear(), [Validators.required, Validators.pattern('^[12][0-9]{3}$'),
        // Double check length
        Validators.maxLength(4), Validators.minLength(4)]],
      toYear: [this.getCurrentYear(), [Validators.required, Validators.pattern('^[12][0-9]{3}$'),
        Validators.maxLength(4), Validators.minLength(4)]],
      startPeriod: ['01', [Validators.required]],
      endPeriod: ['12', [Validators.required]],
      // These have no validation/initial values for now. If empty then try to get without specifying range.
      fromAccount: ['4000', [Validators.minLength(4), Validators.maxLength(4), Validators.pattern('^[0-9]*$'), Validators.required]],
      toAccount: ['7995', [Validators.minLength(4), Validators.maxLength(4), Validators.pattern('^[0-9]*$'), Validators.required]]
    });
    this.hide = false;
    this.forceAll = false;
  }
  onSubmit() {
    const foReportValues = new FoFormValues(this.form, this.forceAll);
    this.formValues.emit(foReportValues);
  }
  // Comparators are mainly used to make it so the "initial" value matches in the GUIs selector.
  periodComparator(c1: string, c2: string): boolean{
    return c1 === c2;
  }
  contextComparator(c1: Context, c2: Context): boolean{
    return c1.id === c2.id;
  }
  getCurrentYear() {
    return this.as.getCurrentYear();
  }
  get fromYear(){return this.form.get('fromYear'); }
  get toYear(){return this.form.get('toYear'); }
  get fromAccount(){return this.form.get('fromAccount'); }
  get toAccount(){return this.form.get('toAccount'); }

  toggleForceAll($event: MatSlideToggleChange) {
    this.forceAll = !$event.checked;
  }
}
