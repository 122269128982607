import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {User} from '../models/User';
import * as constants from '../constants';
import {Context} from '../models/Context';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  name: string;
  defaultName = 'Fo Rapporter';
  contexts = [];
  constructor(private http: HttpClient) {
    this.name = this.defaultName;
    if (localStorage.getItem('loggedInfo')) {
      const user = JSON.parse(localStorage.getItem('loggedInfo')) as User;
      this.name = this.getContextNameFromId(user.role);
    }
  }
  // This is kind off a workaround for the "basic auth". The auth actually used should probably be upgraded to something token based.
  // Which should lead to this being slightly refactored.
  async login(username: string, password: string) {
    const authData = 'Basic ' + btoa(unescape(encodeURIComponent(username + ':' + password)));
    const url = constants.avioLoginPath;
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: authData
      })
    };
    let msg = '';
    let role = '';
    try{
      await this.http.get(url, httpOptions).toPromise().then(data => {
        Object.keys(data).map(key => {
          const d = data[key];
          if (key === 'contextId') {
            role = d;
          }
          if (key === 'message') {
            msg = d;
          }
        });
      }).catch(error => {
        console.log(error);
        msg = error.message;
      });
    } catch (error) {
      console.log(error);
      msg = error.message;
    }
    if ( msg === 'OK') {
      const user = new User();
      user.username = username;
      user.role = role;
      user.token = password;
      localStorage.setItem('loggedInfo', JSON.stringify(user));
      this.name = this.getContextNameFromId(user.role);
    }
    return msg;
  }

  logout() {
    localStorage.removeItem('loggedInfo');
    this.name = this.defaultName;
  }
  getContextNameFromId(contextId: string) {
    let name = '';
    this.getContexts().forEach(c => {
      if (c.id === contextId) {
        name = c.name;
      }
    });
    return name;
  }
  // Kept for now so a "functional" page can be constructed. However this needs to be refactored out if auth is not implemented.
  getContexts() {
    if (this.contexts.length === 0) {
      const contexts = [];
      contexts.push(new Context('1596505', 'Fellesorganisasjonen (Fo)'));
      contexts.push(new Context('3067896', 'Vestfold og Telemark'));
      contexts.push(new Context('3067894', 'Innlandet'));
      contexts.push(new Context('3067922', 'Møre og Romsdal'));
      contexts.push(new Context('3067953', 'Trøndelag'));
      contexts.push(new Context('3067921', 'Nordland'));
      contexts.push(new Context('3067889', 'Oslo'));
      contexts.push(new Context('3067902', 'Agder'));
      contexts.push(new Context('3067893', 'Viken'));
      contexts.push(new Context('3067913', 'Rogaland'));
      contexts.push(new Context('3067920', 'Vestland'));
      contexts.push(new Context('3067910', 'Troms og Finnmark'));
      this.contexts = contexts;
    }
    return this.contexts;
  }
  public isAuthenticated(): boolean {
    return !!localStorage.getItem('loggedInfo');
  }
}
