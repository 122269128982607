// Used to represent 1 branch or avd from old rapport. This needs to also house "summary" values.
import {Reason} from './Reason';

export class Branch{
  // to be shown in GUI.
  account: string;
  name: string;
  // Total or summary of all underlying reason values. Might want this to be "calculated in constructor, but for now it isn't.
  // Might be better as number for sum at bottom.
  totCost: number;
  totBudget: number;
  inPOfTotalBudget: number;
  totRevisedBudget: number;
  inPOfTotalRevisedBudget: number;
  // Breakdown of each "reason" that are used in the "summary".
  reasons: Reason[];
  detailRow = true;

  constructor(account: string, name: string, reasons: Reason[], costs: number, totBudget: number, totRevisedBudget) {
    this.account = account; this.name = name; this.totCost = costs; this.totBudget = totBudget;
    this.inPOfTotalBudget = 0; this.totRevisedBudget = totRevisedBudget; this.inPOfTotalRevisedBudget = 0;
    this.reasons = reasons;
    this.calculateTotals(reasons);
    this.calculateP();
    this.roundOfTotals();
    if (account === undefined || account === '') { this.account = '0'; }
    if (name === undefined || name === '') { this.name = 'Ingen'; }
  }
  calculateTotals(reasons: Reason[]){
    reasons.forEach(reason => {
      this.totCost += reason.costs;
      this.totBudget += reason.budget;
      this.inPOfTotalBudget += reason.inPOfBudget;
      this.totRevisedBudget += reason.revisedBudget;
      this.inPOfTotalRevisedBudget += reason.inPOfRevisedBudget;
    });
  }
  // note it is possible we should not be rounding here, as this can be instead done in the ts/html with toFixed.
  roundOfTotals(){
    // Round off the totals.
    this.totCost = Math.round((this.totCost + Number.EPSILON) * 100) / 100;
    this.totBudget = Math.round((this.totBudget + Number.EPSILON) * 100) / 100;
    this.totRevisedBudget = Math.round(this.totRevisedBudget + Number.EPSILON * 100) / 100;
  }
  // Attempts to set totInRevised etc.
  calculateP(){
    if (this.totCost > 0.0) {
      if (this.totBudget > 0.0) {
        this.inPOfTotalBudget = (this.totCost / this.totBudget) * 100;
      }
      if (this.totRevisedBudget > 0.0) {
        this.inPOfTotalRevisedBudget = (this.totCost / this.totRevisedBudget) * 100;
      }
    }
  }
}
