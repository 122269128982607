import {FormGroup} from '@angular/forms';
import {Context} from './Context';

export class FoFormValues {
  fromYear: string;
  toYear: string;
  startPeriod: string;
  endPeriod: string;
  fromAccount: string;
  toAccount: string;
  forceAll: boolean;
  constructor(form: FormGroup, forceAll: boolean) {
    this.fromYear = form.get('fromYear').value;
    this.toYear = form.get('toYear').value;
    this.startPeriod = form.get('startPeriod').value;
    this.endPeriod = form.get('endPeriod').value;
    this.fromAccount = form.get('fromAccount').value;
    this.toAccount = form.get('toAccount').value;
    this.forceAll = forceAll;
  }
}
