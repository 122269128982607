<div class="sideMenu">
  <div>
    <form [formGroup]="form" class="form">
        <mat-form-field class="field-selectYear"  appearance="fill">
          <mat-label>Regnskapsår</mat-label>
          <input matInput type="text" formControlName="year">
        </mat-form-field>
      <div *ngIf="this.year.invalid && this.year.dirty">
        <!-- There are many ways to implement "error" messages,
        however try to avoid getting several mat-errors as displayed on the same form-field at the same time-->
        <mat-error *ngIf="this.year.errors.required; else fromYearTypeError">Vennligst Angi ett start år.</mat-error>
        <ng-template #fromYearTypeError>
          <mat-error>Formate må være YYYY som f.eks: 2021</mat-error>
        </ng-template>
      </div>
      <mat-form-field appearance="fill">
        <mat-label>Budsjett hovedbok</mat-label>
        <input matInput type="text" formControlName="ledger">
      </mat-form-field>
      <div *ngIf="this.ledger.invalid && this.ledger.dirty">
        <mat-error *ngIf="this.ledger.errors.required; else ledgerTypeError">Vennligst Angi Hovedbok for budsjetter.</mat-error>
        <ng-template #ledgerTypeError>
          <mat-error>Vennligst bruk tall i format xx.</mat-error>
        </ng-template>
      </div>
      <mat-form-field appearance="fill">
        <mat-label>Revidert budsjett hovedbok</mat-label>
        <input matInput type="text" formControlName="revisedLedger">
      </mat-form-field>
      <div *ngIf="this.revisedLedger.invalid && this.revisedLedger.dirty">
        <mat-error *ngIf="this.revisedLedger.errors.required; else revisedLedgerTypeError">Vennligst Angi Hovedbok for reviderte budsjetter.</mat-error>
        <ng-template #revisedLedgerTypeError>
          <mat-error>Vennlisgt bruk tall i format xx.</mat-error>
        </ng-template>
      </div>
        <!-- List all contexts here, then "highlight" current one based on contextId.
        for now just a normal select, but could be a "expanded scroll box" if wanted.-->
      <!-- Kept for now, old way of getting contexts. Uses "userRole" instead now.
        <mat-form-field class="contexts"  appearance="fill">
          <mat-label>Firma</mat-label>
          <mat-select [compareWith]="contextComparator" formControlName="context">
            <mat-option *ngFor="let con of contexts" [value]="con">{{con.name}}</mat-option>
          </mat-select>
        </mat-form-field>
        -->
    </form>
    <button mat-raised-button color="primary" type="submit" (click)="onSubmit()" [disabled]="form.invalid">Submit</button>
  </div>
</div>

