import {Component, OnInit, QueryList, ViewChildren} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AvioComService} from '../services/avio-com.service';
import {BudgetFormValues} from '../models/budgetModels/BudgetFormValues';
import {Location} from '@angular/common';
import {BudgetBranch} from '../models/budgetModels/budgetBranch';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {MatTableDataSource} from '@angular/material/table';
import {SubBudgetComponent} from './sub-budget/sub-budget.component';
import {BudgetReason} from '../models/budgetModels/budgetReason';
import * as constants from '../constants';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-budget-report',
  templateUrl: './budget-report.component.html',
  styleUrls: ['./budget-report.component.css']
})
export class BudgetReportComponent implements OnInit {
  serverMessage = '';
  loading = false;
  // To show on last used.
  year: string;
  ledger: string;
  revisedLedger: string;

  branches: BudgetBranch[];
  displayedColumns: string[] = ['accountNumber', 'accountDesc',  'totBudget', 'totRevisedBudget'];
  tableForm: FormGroup;
  dataSource: MatTableDataSource<BudgetBranch>;
  digitDecString: string;
  sumTotalRevisedBudget: number;
  sumTotalBudget: number;
  show0s: boolean;
  showNone: boolean;
  searched: boolean;
  edited: boolean;
  hadError: boolean;
  // Consider making this service related. Since its the same functionality on 2 different components.
  // For decimal selector.
  decimals = [0, 1, 2];
  selectedDec: number;
  @ViewChildren(SubBudgetComponent) children: QueryList<SubBudgetComponent>;
  constructor(private router: Router,
              private readonly route: ActivatedRoute,
              private as: AvioComService,
              private location: Location,
              private fb: FormBuilder,
              private snackBar: MatSnackBar) {
  }

  ngOnInit(): void {
    this.hadError = false;
    this.edited = false;
    this.searched = false;
    this.show0s = false;
    this.showNone = false;
    this.dataSource = new MatTableDataSource<BudgetBranch>();
    this.tableForm = this.fb.group({});
    this.year = this.as.getCurrentYear();
    this.ledger = '';
    this.revisedLedger = '';
    this.branches = [];
    this.selectedDec = 0;
    this.digitDecString = '1.0-0';
    // this.downloadContextData(this.contextId);
  }
  // Called upon "loading this component/page up", and whenever the menu hits submit.
  async downloadContextData(form: BudgetFormValues) {
    this.loading = true;
    let error = false;
    await this.as.getBudgetContextSpecificData(form).then(ans => {
      if ('data' in ans) {
        this.branches = ans.data;
      }
      if ('message' in ans) {
        this.serverMessage = ans.message;
      }
      if (ans.error) {
        error = ans.error;
      }
    });
    if (this.branches === undefined || this.branches.length === 0) {
      // console.log(this.branches);
      // console.log('routing to unauthorized');
      // Not sure if we actually want to redirect them away, or if we just want to show a "none found/404" in this component itself instead.
      // this.router.navigateByUrl('/pageNotFound');
    }
    this.loading = false;
    if (error) {
      this.hadError = true;
    }
  }
  // TODO put this in html
  toggleHide($event: any) {
    this.show0s = $event.checked;
    // this.children.forEach(c => c.hide0s(this.hide0s));
    if (this.branches !== undefined && this.branches.length > 0) {
      this.dataSource.data = this.filterBranches(this.show0s);
      if (this.children.length > 0) {
        this.children.forEach(c => c.filterReasonsBy0(this.show0s));
      }
      this.calculateTableSummaries();
    }
  }
  // TODO put this in html
  toggleNone($event: any) {
    this.showNone = $event.checked;
    if (this.branches.length > 0) {
      this.dataSource.data = this.filterBranches(this.show0s);
      this.calculateTableSummaries();
      if (this.children.length > 0) {
        this.children.forEach(c => c.filterReasons(this.showNone));
      }
    }
  }
  filterBranches(show0s) {
    let newD = [];
    if (!show0s) {
      newD = this.branches.filter(r => r.totBudget !== 0.0);
    } else {
      newD = this.branches;
    }
    if (!this.showNone) {
      newD = newD.filter(r => r.branchNr !== '00');
    }
    newD = this.sortBranches(newD);
    return newD;
  }
  calculateTableSummaries() {
    let sumTotalBudget = 0.0;
    let sumTotalRevisedBudget = 0.0;
    for (const b of this.dataSource.data) {
      sumTotalBudget += b.totBudget;
      sumTotalRevisedBudget += b.totRevisedBudget;
    }
    this.sumTotalBudget = sumTotalBudget;
    this.sumTotalRevisedBudget = sumTotalRevisedBudget;
  }
  sortBranches(branches: BudgetBranch[]) {
    return branches.sort((b1, b2) => Number(b1.branchNr) - Number(b2.branchNr));
  }
  async menuSubmit($event: BudgetFormValues) {
    this.loading = true;
    this.tableForm = this.fb.group({});
    this.hadError = false;
    this.serverMessage = '';
    await this.downloadContextData($event);
    this.year = $event.year;
    this.ledger = $event.ledger;
    this.revisedLedger = $event.revisedLedger;
    this.searched = true;
    this.edited = false;
    // this.contextName = $event.context.name;
    // this.contextId = $event.context.id;
    // This should just update the url, and not "activate" routing/ngOninit here etc.
    // this.location.replaceState('/budgetReport/' + this.contextId);
    this.branches = this.sortBranches(this.branches);
    this.toggleNone({checked: this.showNone});
    this.loading = false;
  }
  /*get budgetArray() {
    return this.tableForm.get('budgets') as FormArray;
  }*/
  // This is used because setting decimalPipe in html based on string interpolation doesn't really work. So data binding is used instead.
  // Unused here for now, as it uses number inputs instead.

  changeDecText($event: number) {
    this.digitDecString = '1.' + $event + '-' + $event;
    this.children.forEach(c => c.changeDecText($event));
  }
  async onSubmit() {
    this.loading = true;
    if (this.hadError) {
      // TODO fix this showing better message.
      console.log('Stopped sending due to error');
      return;
    }
    // TODO POST DATA, turn off test check.
    const branches = this.dataSource.data;
    let ok = true;
    branches.forEach(b => {
      if (!b.updatePeriods()) {
        ok = false;
      }
    });
    if (!ok) {
      console.log('Something went wrong while attempting to update periods...');
      this.hadError = true;
      this.serverMessage = 'Noe gikk galt under kalkulering av perioder. Vennligst kontakt avio support.';
      this.loading = false;
      return;
    }
    const ans = await this.as.submitBudgets(branches);
    if (ans.error) {
      this.serverMessage = ans.message;
      this.hadError = true;
      this.loading = false;
      return;
    }
    // TODO only do this on success.
    this.branches.forEach(b => b.swapRevisedToExists());
    this.snackBar.open(ans.message, 'Fjern', {
      duration: 4000
    });
    this.loading = false;
  }

  onEdit($event: any) {
    this.hadError = $event.hadError;
    if (!this.hadError) {
      const reasons: BudgetReason[] = $event.reasons;
      const b = this.branches.filter(br => br.branchNr === $event.branchNr)[0];
      b.reasons = reasons;
      b.updateReasons(reasons);
      this.edited = true;
      this.toggleNone({checked: this.showNone});
    } else {
      this.serverMessage = $event.message;
    }
  }

  getEditMessage(): string {
    if (this.edited) {
      return 'Endringer har blitt gjort etter henting';
    } else {
      return 'Ingen endringer har blitt gjort etter henting';
    }
  }
}
