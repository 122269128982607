// This is used as 1 Formål from old formål rapport.
import {Transaction} from './Transaction';

export class Reason {
  // more like subText like 101? thats how the "old" one looked at least. Should maybe be string.
  id: number;
  name: string;
  // These maybe should not be string, also pOfBudsjett might not be needed stored like this?
  costs: number;
  budget: number;
  inPOfBudget: number;
  revisedBudget: number;
  inPOfRevisedBudget: number;
  transactions: Transaction[];
  detailRow = true;
  // Split based on acc nr.
  subAccount: string;
  reasonNumber: string;
  tariff: string;
  sumDebitAmount: number;
  sumCreditAmount: number;
  constructor(id: number, name: string, costs: number, budget: number,
              inPOfBudget: number, revisedBudget: number, inPOfRevisedBudget: number, transactions: Transaction[]) {
    this.id = id; this.name = name; this.costs = costs; this.budget = budget; this.inPOfBudget = inPOfBudget;
    this.revisedBudget = revisedBudget; this.inPOfRevisedBudget = inPOfRevisedBudget; this.transactions = transactions;
    this.sumCreditAmount = 0.0; this.sumDebitAmount = 0.0; this.splitId(); this.caluclateTransSums(); this.calculatePercentages();
  }
  splitId(){
    const id = this.id + '';
    if (id.length < 5) {
      this.subAccount = '';
      this.reasonNumber = id;
      this.tariff = '';
    } else {
      this.subAccount = id.substring(0, 2);
      this.reasonNumber = id.substring(2, 5);
      this.tariff = '';
    }
  }
  // for summary row.
  caluclateTransSums() {
    let sumCred = 0.0;
    let sumDeb = 0.0;
    for (const t of this.transactions) {
      sumCred += Number(t.creditAmount);
      sumDeb += Number(t.debitAmount);
    }
    this.sumCreditAmount = sumCred;
    this.sumDebitAmount = sumDeb;
  }
  calculatePercentages() {
    if (this.costs > 0.0) {
      if (this.budget > 0.0) {
        this.inPOfBudget = (this.costs / this.budget) * 100;
      }
      if (this.revisedBudget > 0.0) {
        this.inPOfRevisedBudget = (this.costs / this.revisedBudget) * 100;
      }
    }
  }
}


