import {BudgetReason} from './budgetReason';
import {Reason} from '../Reason';

export class BudgetBranch {
  branchNr: string;
  description: string;
  totBudget: number;
  totRevisedBudget: number;
  reasons: BudgetReason[];
  ledger: string;
  revisedLedger: string;
  constructor(branchNr: string, description: string, totBudget: number, totRevisedBudget: number,
              reasons: BudgetReason[], ledger: string, revisedLedger: string) {
    this.branchNr = branchNr;
    this.description = description;
    this.totBudget = totBudget;
    this.totRevisedBudget = totRevisedBudget;
    this.ledger = ledger;
    this.revisedLedger = revisedLedger;
    if (totRevisedBudget === undefined) {
      this.totRevisedBudget = 0.0;
    }
    this.reasons = reasons;
    this.calculateTotals(reasons);
  }
  calculateTotals(reasons: BudgetReason[]) {
    this.totBudget = 0.0;
    this.totRevisedBudget = 0.0;
    reasons.forEach(reason => {
      this.totBudget += reason.amount;
      this.totRevisedBudget += reason.revisedBudget;
    });
    // Round off the totals.
    this.totBudget = Math.round((this.totBudget + Number.EPSILON) * 100) / 100;
    this.totRevisedBudget = Math.round((this.totRevisedBudget + Number.EPSILON) * 100) / 100;
  }

  updateReasons(reasons: BudgetReason[]) {
    this.reasons = reasons;
    this.calculateTotals(reasons);
  }
  // Ensure this has been called with "latest" data before submitting. This ensure the period split is accurate with the "total" desired.
  updatePeriods(): boolean {
    let ok = true;
    this.reasons.forEach(r => {
      if (!r.updatePeriods()) {
        ok = false;
      }
    });
    return ok;
  }
  // Use this function to update what is deemed "acceptable values" for the entire branch. There is a similare one for budgets.
  validateBudgetBranch(): boolean {
    let totBudg = 0.0;
    let totRBudg = 0.0;
    for (const r of this.reasons) {
      const valid = r.validateReasons();
      if (!valid) {
        console.log('One reason was not valid:');
        console.log(r);
        return false;
      }
      totBudg += r.amount;
      totRBudg += r.revisedBudget;
    }
    // The checking of totals is to ensure whatever is "shown at the top bar" is correct.
    // Needs to be done if that is ever used as a reference for if the budgets are the correct value.
    if (totBudg !== this.totBudget) {
      console.log('Budget divergence calculated total: ' + totBudg + ', expected total: ' + this.totBudget);
      return false;
    }
    if (totRBudg !== this.totRevisedBudget) {
      console.log('Revised Budget divergence calculated total: ' + totRBudg + ', expected total: ' + this.totRevisedBudget);
      return false;
    }
    // if you make it here its valid.
    return true;
  }

  swapRevisedToExists() {
    this.reasons.forEach(r => r.submitReset());
  }
}
