import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {Reason} from '../../../../models/Reason';
import {MatTableDataSource} from '@angular/material/table';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {ContextMenuService, DataType} from '../../../../services/context-menu.service';
import {delay} from 'rxjs/operators';

@Component({
  selector: 'app-branch',
  templateUrl: './branch.component.html',
  styleUrls: ['./branch.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      // Note if you increase the animate here, it will work. However it was causing quite choppy animations.
      // I could not find a good fix for this. So another approach might be better.
      transition('expanded <=> collapsed', animate('0s')),
    ]),
  ],
})
export class BranchComponent implements OnInit {
  @Input() reasons: Reason[];
  @Input() branch: string;
  @Input() decimals: number;
  // Only really use on init.
  @Input() showNone: boolean;
  // Only really used on init
  @Input() show0: boolean;
  dataSource: MatTableDataSource<Reason>;
  displayedColumns: string[] = ['id', 'name', 'costs', 'budget',
    'inPOfBudget', 'revisedBudget', 'inPOfRevisedBudget'];
  hideAll: boolean;
  // TO seperate none and 0 logic out.
  hideAll0s: boolean;
  // references the currently "show more info" selected row. Can be undefined.
  expandedElement;
  // Summary row data here. Update whenever the tables data changes.
  digitDecString: string;
  sumCosts: number;
  sumBudget: number;
  sumRevisedBudget: number;
  sumPOfBudget: number;
  sumPOfRevisedBudget: number;
  constructor(private contextMenuService: ContextMenuService) { }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<Reason>(this.reasons);
    this.hideAll = false;
    this.sortReasons();
    this.changeDecText(this.decimals);
    if (this.reasons.length === 0 ) {
      this.hideAll = true;
    }
    this.filterReasons(this.showNone);
    this.filterTransactionsBy0(this.show0);
    this.calculateTableSummaries();
  }
  isExpansionDetailRow = (i: number, row) => row.hasOwnProperty('detailRow');
  setExpanded(row) {
    if (this.expandedElement === row) {
      this.expandedElement = undefined;
    } else {
      this.expandedElement = row;
    }
  }
  sortReasons() {
    this.reasons.sort((r1, r2) => Number(r1.id) - Number(r2.id));
  }
  onRightClick($event: MouseEvent, reason) {
    $event.preventDefault();
    let popupXPosition; let popupYPosition;
    if ($event.clientX + this.contextMenuService.popupWidth > window.innerWidth) {
      popupXPosition = $event.pageX - this.contextMenuService.popupWidth;
    } else {
      popupXPosition = $event.pageX;
    }
    if ($event.clientY + this.contextMenuService.popupHeight > window.innerHeight) {
      popupYPosition = $event.pageY - this.contextMenuService.popupHeight;
    } else {
      popupYPosition = $event.pageY;
    }
    this.contextMenuService.showContextMenu( popupXPosition, popupYPosition, reason, DataType.Reason);
  }
  filterReasons(show: boolean) {
    if (this.branch.substring(0, 2) === '00'  && this.reasons.length !== 0) {
      this.hideAll = !show;
    }
  }
  changeDecText($event: number) {
    this.digitDecString = '1.' + $event + '-' + $event;
  }
  filterTransactionsBy0(show0s: boolean) {
    this.hideAll0s = false;
    this.show0 = show0s;
    let newR = [];
    if (!show0s) {
      newR = this.reasons.filter(r => r.costs !== 0.0);
    } else {
      newR = this.reasons;
    }
    let sum = 0.0;
    newR.forEach(r => sum += r.costs);
    if (!show0s) {
      this.hideAll0s = sum === 0.0;
    }
    this.dataSource.data = newR;
  }
  // Could maybe have used input instead. However doing it here ensure later we dont "detach" based on setting changes of what to show.
  // Updates the variables of "summary" row.
  calculateTableSummaries() {
    let sumCosts = 0.0;
    let sumBudget = 0.0;
    let sumRevisedBudget = 0.0;
    let sumPOfBudget = 0.0;
    let sumPOfRevisedBudget = 0.0;
    for (const r of this.dataSource.data) {
      sumCosts += r.costs;
      sumBudget += r.budget;
      sumRevisedBudget += r.revisedBudget;
    }
    if (sumCosts > 0) {
      if (sumBudget > 0.0) {
        sumPOfBudget = (sumCosts / sumBudget) * 100;
      }
      if (sumPOfRevisedBudget > 0.0) {
        sumPOfRevisedBudget = (sumCosts / sumRevisedBudget) * 100;
      }
    }
    this.sumCosts = sumCosts;
    this.sumBudget  = sumBudget ;
    this.sumRevisedBudget  =  sumRevisedBudget ;
    this.sumPOfBudget = sumPOfBudget;
    this.sumPOfRevisedBudget =  sumPOfRevisedBudget;
  }
}
