import {Injectable} from '@angular/core';
import {Branch} from '../models/Branch';
import {Reason} from '../models/Reason';
import {FoFormValues} from '../models/FoFormValues';
import * as constants from '../constants';
import {useTestBase} from '../constants';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import FoRapportTransactions from '../assets/FoRapportTransactions.json';
import BudgetFile from '../assets/BudgetTest.json';
import {BudgetFormValues} from '../models/budgetModels/BudgetFormValues';
import {User} from '../models/User';
import {Transaction, VismaAccount} from '../models/Transaction';
import {BudgetBranch} from '../models/budgetModels/budgetBranch';
import {BudgetReason, Period} from '../models/budgetModels/budgetReason';

@Injectable({
  providedIn: 'root'
})
// This class should act as the "communicator" between this page, and the proline server api.
// Also for now has some helper functions that might want to either get from visma, or put in its own service.
export class AvioComService {
  constructor( private http: HttpClient) {
  }
  getPeriods() {
    const periods = [];
    periods.push('01');
    periods.push('02');
    periods.push('03');
    periods.push('04');
    periods.push('05');
    periods.push('06');
    periods.push('07');
    periods.push('08');
    periods.push('09');
    periods.push('10');
    periods.push('11');
    periods.push('12');
    return periods;
  }

  getCurrentYear() {
    return new Date().getFullYear() + '';
  }
  // same as getFOContext, but uses parameters from the menu to change filters.
  async getFOContextSpecificData(foFormValues: FoFormValues) {
    if (constants.test) {
      return this.getFoTestData();
    }
    return await this.getFoRealData(foFormValues.fromYear,
      foFormValues.toYear, foFormValues.startPeriod, foFormValues.endPeriod, foFormValues.fromAccount,
      foFormValues.toAccount, foFormValues.forceAll);
  }
  async getFOContextData(contextId: string) {
    let data;
    if (constants.test) {
      data = await this.getFoTestData();
    } else {
      // data = this.getFoRealData(contextId, this.getCurrentYear(), this.getCurrentYear(), '01', '12');
    }
    return data;
  }
  // This is called as a "get default data".
  async getFoRealData(fromYear: string, toYear: string, startPeriod: string, endPeriod: string,
                      fromAccount: string, toAccount: string, forceAll: boolean) {
    console.log('Trying to get data');
    const user =  JSON.parse(localStorage.getItem('loggedInfo')) as User;
    const authData = 'Basic ' + btoa(unescape(encodeURIComponent(user.username + ':' + user.token)));
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        Authorization: authData
      })
    };
    let message = '';
    console.log(forceAll);
    const url = constants.avioFoServletPath + '?fromPeriod=' + fromYear + startPeriod + '&toPeriod=' + toYear + endPeriod + '' +
      '&fromAccount=' + fromAccount + '&toAccount=' + toAccount + '&forceAll=' + forceAll;
    console.log(url);
    console.log(authData);
    return await this.http.get(url, httpOptions).toPromise().then(data => {
      console.log('Call data gotten:');
      console.log(data);
      // Build branches array here, then return.
      let branches: Branch[] = [];
      // tslint:disable-next-line:forin
      for (const dataKey in data) {
        if (dataKey === 'message') {
          message = data[dataKey];
        } else if (dataKey === 'data') {
          const branchArr = data[dataKey];
          console.log(branchArr);
          branches = this.convertDataArrToBranches(branchArr);
        }
      }
      return {
        message,
        data: branches,
        error: false
      };
    }).catch((error) => {
      console.log('error on get:');
      console.log(error);
      const text = error.message.replace(/<[^>]*>/g, '');
      return {message: text, data: [], error: true};
    });
  }
  convertDataArrToBudgetBranches(data: any[]): BudgetBranch[] {
    console.log('Converting');
    console.log(data);
    const branches: BudgetBranch[] = [];
    for (const b of data) {
      console.log(b);
      const rs = b.reasons;
      const cRs = [];
      for (const r of rs) {
        const periods = [];
        const ps = r.periods;
        for (const p of ps) {
          periods.push(new Period(p.periodId, p.amount, p.exists));
        }
        const reason = new BudgetReason(r.financialYear, r.released, r.releasedAmount, r.account.number, r.account.accountDesc,
          r.subaccount.id, r.subaccount.description, r.description, r.amount, r.distributedAmount, ps, r.revisedBudget,
          r.branchNumber.number, r.branchNumber.name, r.revisedPeriods, r.subaccountSegments, r.revisedExists);
        cRs.push(reason);
      }
      const branch = new BudgetBranch(b.number, b.name, b.budget, b.revisedBudget, cRs, b.ledger, b.revisedLedger);
      branches.push(branch);
    }
    return branches;
  }
  convertDataArrToBranches(data: any[]): Branch[] {
    const branches: Branch[] = [];
    for (const branch of data) {
      console.log(branch);
      const rs = branch.reasons;
      const cRs = [];
      for (const r of rs) {
        const transactions = r.transactionList;
        const ts = [];
        for (const t of transactions) {
          const va = new VismaAccount(t.account.type, t.account.number, t.account.description );
          const ta = new Transaction(t.tranDate, t.module, t.batchNumber, t.period, t.description, t.refNumber, t.branch.name, va,
            t.subaccount, t.begBalance, t.debitAmount, t.creditAmount, t.endingBalance, t.currency, t.currBegBalance, t.currDebitAmount,
            t.currCreditAmount, t.currEndingBalance, t.lastModifiedDateTime);
          ts.push(ta);
        }
        const reason = new Reason(r.id, r.name, r.costs, r.budget, r.inPOfBudget, r.revisedBudget, r.inPOfRevisedBudget, ts);
        cRs.push(reason);
      }
      const b = new Branch(branch.account, branch.name, cRs, branch.totCosts, branch.totBudget, branch.totRevisedBudget);
      branches.push(b);
    }
    return branches;
  }
  // This data is just made for testing before the api wokrs, it mimics the old report data structure,
  // and might not align with naming in real data. Do not copy paste new api data in here.
  getFoTestData(){
    const file = FoRapportTransactions;
    const branches = this.convertDataArrToBranches(file);
    const message = '';
    return {
      message,
      data: branches,
      error: false
    };
  }

  async getBudgetContextSpecificData($event: BudgetFormValues) {
    if (constants.test) {
      return await this.getBudgetTestData();
    } else {
      return await this.getBudgetRealData($event.year, $event.ledger, $event.revisedLedger);
    }
  }
  private async getBudgetTestData() {
    const file = BudgetFile;
    const branches = this.convertDataArrToBudgetBranches(file);
    const message = '';
    return {message, data: branches, error: false};
  }
  private  async getBudgetRealData(year: string, ledger: string, revisedLedger: string) {
    console.log('Trying to get data');
    const user = JSON.parse(localStorage.getItem('loggedInfo')) as User;
    const url = constants.avioBudgetServletPath + '?year=' + year + '&ledger=' + ledger +
      '&revisedLedger=' + revisedLedger + '&testBase=' + useTestBase;
    const authData = 'Basic ' + btoa(unescape(encodeURIComponent(user.username + ':' + user.token)));
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        Authorization: authData
      })
    };
    let message = '';
    return await this.http.get(url, httpOptions).toPromise().then(data => {
      console.log('Call data gotten:');
      console.log(data);
      // Build branches array here, then return.
      let branches = [];
      // tslint:disable-next-line:forin
      for (const dataKey in data) {
        if (dataKey === 'message') {
          message = data[dataKey];
        } else if (dataKey === 'data') {
          branches = this.convertDataArrToBudgetBranches(data[dataKey]);
        }
      }
      return {
        message,
        data: branches,
        error: false
      };
    }).catch((error) => {
      console.log('error on get:');
      console.log(error);
      const text = error.message.replace(/<[^>]*>/g, '');
      return {
        message: text,
        data: [],
        error: true};
    });
  }

  async submitBudgets(branches: BudgetBranch[]) {
    console.log('Sending budgets to avio....');
    const user = JSON.parse(localStorage.getItem('loggedInfo')) as User;
    const url = constants.avioBudgetServletPath + '?testBase=' + useTestBase;
    const authData = 'Basic ' + btoa(unescape(encodeURIComponent(user.username + ':' + user.token)));
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        Authorization: authData
      })
    };
    let message = '';
    console.log(JSON.stringify(branches));
    return await this.http.put(url, JSON.stringify(branches), httpOptions).toPromise().then(data => {
      console.log('Call data gotten:');
      console.log(data);
      for (const dataKey in data) {
        if (dataKey === 'message') {
          message = data[dataKey];
        }
      }
      return {
        message,
        data: branches,
        error: false
      };
    }).catch((error) => {
      console.log('error on get:');
      console.log(error);
      const text = error.message.replace(/<[^>]*>/g, '');
      return {
        message: text,
        data: [],
        error: true};
    });
  }
}
