import {ChangeDetectionStrategy, Component, OnInit, QueryList, ViewChildren} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Branch} from '../../models/Branch';
import {AvioComService} from '../../services/avio-com.service';
import {FoFormValues} from '../../models/FoFormValues';
import {Location} from '@angular/common';
import {BranchComponent} from './subParts/branch/branch.component';
import {MatTableDataSource} from '@angular/material/table';
import { animate, state, style, transition, trigger } from '@angular/animations';
import {ContextMenuService, Menu} from '../../services/context-menu.service';
import {ExportService} from '../../services/export.service';
import {Reason} from '../../models/Reason';
import {fakeAsync} from '@angular/core/testing';
@Component({
  selector: 'app-fo-report',
  templateUrl: './fo-report.component.html',
  styleUrls: ['./fo-report.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class FoReportComponent implements OnInit {
  branches: Branch[];
  displayedColumns: string[] = ['account', 'name', 'totCost', 'totBudget',
    'inPOfTotalBudget', 'totRevisedBudget', 'inPOfTotalRevisedBudget'];
  loading = false;
  // just stored for "last used".
  startYear: string;
  endYear: string;
  startPeriod: string;
  endPeriod: string;
  fromAccount: string;
  toAccount: string;
  // For decimal selector.
  decimals = [0, 1, 2];
  selectedDec: number;
  show0s: boolean;
  showNone: boolean;
  hadError: boolean;
  dataSource: MatTableDataSource<Branch>;
  @ViewChildren(BranchComponent) children: QueryList<BranchComponent>;
  digitDecString: string;
  serverMessage: string;
  // references the currently "show more info" selected row. Can be undefined.
  expandedElement;
  contextMenu: Menu;
  searched: boolean;
  // Summary row data. Update these whenever the data shown in the table is modified.
  sumInPOfTotalRevisedBudget = 0.0;
  sumTotRevisedBudget = 0.0;
  sumInPOfTotalBudget = 0.0;
  sumTotBudget = 0.0;
  sumTotCost = 0.0;

  constructor( private readonly route: ActivatedRoute,
               private router: Router,
               private as: AvioComService,
               private location: Location,
               private contextMenuService: ContextMenuService,
               private exportService: ExportService) {}

  ngOnInit(): void {
    this.hadError = false;
    this.searched = false;
    // Note this breaks if it starts on true. Some logic in the child will need to be changed.
    this.show0s = false;
    this.startPeriod = '01';
    this.endPeriod = '12';
    this.startYear = this.as.getCurrentYear();
    this.endYear = this.as.getCurrentYear();
    // this.downloadContextData(this.contextId);
    // Change this for starting decimal
    this.selectedDec = 0;
    this.digitDecString = '1.0-0';
    this.serverMessage = '';
    this.dataSource = new MatTableDataSource<Branch>(this.branches);
    this.showNone = false;
    this.contextMenuService.getContextMenuSub().subscribe(menu => {
      this.contextMenu = menu;
    });
  }
  // Not sure if this is  worth the time it takes, but will make the acc nr go from lowest to highest.
  numberComparator(t1: number, t2: number): boolean {
  return t1.valueOf() === t2.valueOf();
}
  sortBranches(branches: Branch[]){
    return branches.sort((b1, b2) => Number(b1.account) - Number(b2.account));
  }
  async menuSubmit($event: FoFormValues) {
    this.loading = true;
    this.hadError = false;
    this.serverMessage = '';
    this.startPeriod = $event.startPeriod;
    this.endPeriod = $event.endPeriod;
    this.startYear = $event.fromYear;
    this.endYear = $event.toYear;
    this.fromAccount = $event.fromAccount;
    this.toAccount = $event.toAccount;
    this.searched = true;
    const ans = await this.as.getFOContextSpecificData($event);
    if ('data' in ans) {
      this.branches = ans.data;
      this.branches = this.sortBranches(this.branches);
      this.dataSource.data = this.filterBranches(this.show0s);
      this.toggleHide({checked: this.show0s});
      this.toggleNone({checked: this.showNone});
      this.calculateTableSummaries();
    }
    if ('message' in ans) {
      this.serverMessage = ans.message;
    }
    this.loading = false;
    this.hadError = ans.error;
  }
  toggleHide($event: any) {
    this.show0s = $event.checked;
    if (this.branches !== undefined && this.branches.length > 0) {
      this.dataSource.data = this.filterBranches(this.show0s);
      this.children.forEach(c => c.filterTransactionsBy0(this.show0s));
      this.calculateTableSummaries();
    }
  }
  toggleNone($event: any) {
      this.showNone = $event.checked;
      if (this.branches !== undefined && this.branches.length > 0) {
        this.dataSource.data = this.filterBranches(this.show0s);
        this.calculateTableSummaries();
        if (this.children.length > 0) {
          this.children.forEach(c => c.filterReasons(this.showNone));
        }
      }
  }
  filterBranches(show0s) {
    let newD = [];
    if (!show0s) {
      newD = this.branches.filter(r => r.totCost !== 0.0);
    } else {
      newD = this.branches;
    }
    if (!this.showNone) {
      newD = newD.filter(r => r.account !== '00');
    }
    newD = this.sortBranches(newD);
    return newD;
  }
  isExpansionDetailRow = (i: number, row) => row.hasOwnProperty('detailRow');
  // This is used because setting decimalPipe in html based on string interpolation doesn't really work. So data binding is used instead.
  changeDecText($event: number) {
    this.digitDecString = '1.' + $event + '-' + $event;
    this.children.forEach(c => c.changeDecText($event));
  }
  // Uncomment this if you want them to expand like branches do.
  setExpanded(row) {
    /*
    if (this.expandedElement === row) {
      this.expandedElement = undefined;
    } else {
      this.expandedElement = row;
    }
     */
  }
  exportFoReportToExcel() {
    let newD: Branch[] = [];
    if (!this.show0s) {
    for (const b of this.dataSource.data) {
      const newR: Reason[] = [];
      for (const r of b.reasons) {
        if (r.costs !== 0.0) {
          newR.push(r);
        }
      }
      if (Reason.length > 0) {
        b.reasons = newR;
        b.calculateTotals(b.reasons);
        b.calculateP();
        b.roundOfTotals();
        newD.push(b);
      }
    }
   } else {
      newD = this.dataSource.data;
    }
    this.exportService.exportFoRepportToExcel(newD);
  }
  // Updates the variables of "summary" row.
  calculateTableSummaries() {
    let sumInPOfTotalRevisedBudget = 0.0;
    let sumTotRevisedBudget = 0.0;
    let sumInPOfTotalBudget = 0.0;
    let sumTotBudget = 0.0;
    let sumTotCost = 0.0;
    for (const b of this.dataSource.data) {
        sumTotCost += b.totCost;
        sumTotBudget += b.totBudget;
        sumTotRevisedBudget += b.totRevisedBudget;
    }
    if (sumTotCost > 0) {
      if (sumTotBudget > 0.0) {
        sumInPOfTotalBudget = (sumTotCost / sumTotBudget) * 100;
      }
      if (sumTotRevisedBudget > 0.0) {
        sumInPOfTotalRevisedBudget = (sumTotCost / sumTotRevisedBudget) * 100;
      }
    }
    this.sumInPOfTotalRevisedBudget =  sumInPOfTotalRevisedBudget;
    this.sumTotRevisedBudget = sumTotRevisedBudget;
    this.sumInPOfTotalBudget =  sumInPOfTotalBudget;
    this.sumTotBudget = sumTotBudget;
    this.sumTotCost =  sumTotCost;
    this.children.forEach(c => c.calculateTableSummaries());
  }
}
