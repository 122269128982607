import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AvioComService} from '../services/avio-com.service';
import {Router} from '@angular/router';
import {User} from '../models/User';
import {AuthService} from '../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  form: FormGroup;
  loading: boolean;
  serverMessage: string;
  hide: boolean;
  constructor( private fb: FormBuilder, private auth: AuthService,
               private router: Router) { }
  // TODO fix mulitple tabs error of using first one isntead etc. Update tab name and stuff.
  ngOnInit(): void {
    this.form = this.fb.group({
      username: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(25)]],
      password: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(25)]]
    });
    this.loading = false;
    this.serverMessage = '';
    this.hide = true;
  }

  async onSubmit() {
    console.log('attempting login...');
    this.loading = true;
    this.serverMessage = '';
    const ans = await this.auth.login(this.username.value, this.password.value);
    this.loading = false;
    if (ans === 'OK') {
      const user = JSON.parse(localStorage.getItem('loggedInfo')) as User;
      this.router.navigateByUrl('/foReport');
    } else if (ans === 'Invalid') {
      this.serverMessage = 'Feil brukernavn eller passord.';
    }else {
      this.serverMessage = ans;
    }
  }
  get username(){ return this.form.get('username'); }
  get password(){ return this.form.get('password'); }
}
