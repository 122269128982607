import {FormGroup} from '@angular/forms';
import {Context} from '../Context';

export class BudgetFormValues {
  year: string;
  ledger: string;
  revisedLedger: string;
  constructor(form: FormGroup) {
    this.year = form.get('year').value;
    this.ledger = form.get('ledger').value;
    this.revisedLedger = form.get('revisedLedger').value;
    // this.context = form.get('context').value;
  }
}
