export class User {
  username: string;
  // Might want to use this for "context used"
  role: string;
  // Instead of Basic auth probably apply token here and use this instead
  // For now this is actually "password" during the basic auth header creation
  token: string;
  // easy toggle for gui/routing only
  isLoggedIn: boolean;
}
