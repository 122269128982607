import {ChangeDetectionStrategy, Component, HostListener} from '@angular/core';
import {ContextMenuService, Menu} from './services/context-menu.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  // This will simply "delete" the stored user data whenever it is refresehd/browser is closed. Simple yett it works.
  @HostListener('window:beforeunload', ['$event'])
  clearLocalStorage(event){
    localStorage.clear();
  }
  constructor(private contextMenuService: ContextMenuService) {
  }
  disableContextMenu() {
    this.contextMenuService.closeMenu();
  }
}
