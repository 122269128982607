import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { FoReportComponent } from './foReport/fo-report/fo-report.component';
import { MenuComponent } from './foReport/fo-report/subParts/menu/menu.component';
import { BranchComponent } from './foReport/fo-report/subParts/branch/branch.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatTableModule} from '@angular/material/table';
import { UrlErrorComponent } from './errorPages/url-error/url-error.component';
import { AuthErrorComponent } from './errorPages/auth-error/auth-error.component';
import { AppRoutingModule } from './app-routing.module';
import { FooterComponent } from './index/footer/footer.component';
import { HeaderComponent } from './index/header/header.component';
import {MatButtonModule} from '@angular/material/button';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatDividerModule} from '@angular/material/divider';
import {FlexLayoutModule} from '@angular/flex-layout';
import { ContextErrorComponent } from './errorPages/context-error/context-error.component';
import {HttpClientModule} from '@angular/common/http';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { BudgetReportComponent } from './budget-report/budget-report.component';
import { SubBudgetComponent } from './budget-report/sub-budget/sub-budget.component';
import { BudgetMenuComponent } from './budget-report/budget-menu/budget-menu.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatRippleModule} from '@angular/material/core';
import { LOCALE_ID} from '@angular/core';
import localeNor from '@angular/common/locales/nb';
import localeNorExtra from '@angular/common/locales/nb';
import {registerLocaleData} from '@angular/common';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {MatIcon, MatIconModule} from '@angular/material/icon';
import { LoginComponent } from './login/login.component';
import {MatCardModule} from '@angular/material/card';
import { FaqComponent } from './faq/faq.component';
import {MatExpansionModule} from '@angular/material/expansion';
import { ContextMenuComponent } from './context-menu/context-menu.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';

registerLocaleData(localeNor, 'no', localeNorExtra);
@NgModule({
  declarations: [
    AppComponent,
    FoReportComponent,
    MenuComponent,
    BranchComponent,
    UrlErrorComponent,
    AuthErrorComponent,
    FooterComponent,
    HeaderComponent,
    ContextErrorComponent,
    BudgetReportComponent,
    SubBudgetComponent,
    BudgetMenuComponent,
    LoginComponent,
    FaqComponent,
    ContextMenuComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatCheckboxModule,
    MatTableModule,
    AppRoutingModule,
    MatButtonModule,
    MatGridListModule,
    MatDividerModule,
    FlexLayoutModule,
    HttpClientModule,
    MatProgressSpinnerModule,
    MatToolbarModule,
    MatRippleModule,
    FormsModule,
    MatSlideToggleModule,
    MatIconModule,
    MatCardModule,
    MatExpansionModule,
    BrowserAnimationsModule,
    MatSnackBarModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'no' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
