<!-- This is the menu for choosing values -->
<div>
  <div class="sec1 sideMenu" fxFlex="210px">
    <app-menu (formValues)="menuSubmit($event)"></app-menu>
  </div>
  <div fxFlex="calc(100% - 210px)">
    <div class="sec2" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center center">
      <div class="infoCard">
        <mat-card class="infoCardFill">
          <mat-card-title>
            <mat-icon mat-card-avatar class="material-icons-two-tone">search</mat-icon>
            <mat-card-title>Brukte felt for siste søk</mat-card-title>
          </mat-card-title>
          <mat-card-content *ngIf="searched" class="infoCardContent">
            <ul class="infoCardList">
              <li> år: {{this.startYear}} til {{this.endYear}}</li>
              <li> periode: {{this.startPeriod}} til {{this.endPeriod}}</li>
              <li> Konto: {{this.fromAccount}} til {{this.toAccount}}</li>
            </ul>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="adjustMenu">
        <mat-card class="adjustMenuFill">
          <mat-card-title>
            <mat-icon mat-card-avatar class="material-icons-two-tone">settings</mat-icon>
            <mat-card-title>Juster visning</mat-card-title>
          </mat-card-title>
          <mat-card-content>
            <mat-form-field appearance="outline">
              <mat-label>Velg antall desimaler</mat-label>
              <mat-select [(ngModel)]="selectedDec" (valueChange)="changeDecText($event)">
                <mat-option *ngFor="let dec of decimals" [value]="dec">{{dec}}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-slide-toggle  style="margin-left: 20px" [checked]="show0s" (change)="toggleHide($event)">Vis formål med 0.</mat-slide-toggle>
            <mat-slide-toggle  style="margin-left: 20px" [checked]="showNone" (change)="toggleNone($event)">Vis "Ingen".</mat-slide-toggle>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <div class="branches" *ngIf="!loading; else loadingAnimation" fxLayoutAlign="center center">
      <div *ngIf="!hadError; else errorMessage">
        <div *ngIf="dataSource.data.length>0; else emptyTable">
          <h2>Formålsrapport:  <button mat-raised-button color="primary" (click)="exportFoReportToExcel()">Eksporter rapport</button></h2>
          <div class="allBranchesSummary">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" multiTemplateDataRows>
              <ng-container matColumnDef="account">
                <mat-header-cell *matHeaderCellDef> Grupperings nr. </mat-header-cell>
                <mat-cell *matCellDef="let branch"> {{branch.account}} </mat-cell>
                <mat-footer-cell *matFooterCellDef> </mat-footer-cell>
              </ng-container>
              <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef> Navn </mat-header-cell>
                <mat-cell *matCellDef="let branch"> {{branch.name}} </mat-cell>
                <mat-footer-cell *matFooterCellDef> SUM: </mat-footer-cell>
              </ng-container>
              <ng-container matColumnDef="totCost">
                <mat-header-cell *matHeaderCellDef> Kostnader </mat-header-cell>
                <!-- Note the | number: '' 'no' makes it so numbers have the thousand separator, and it also overrides the decimal icon.
                I also believe it overrides toFixed a bit, so that we do not get for example 1.00 for 2 decimals, whenever there are "no" decimals.-->
                <mat-cell *matCellDef="let branch"> {{branch.totCost | number: this.digitDecString: 'no'}} </mat-cell>
                <mat-footer-cell *matFooterCellDef> {{this.sumTotCost | number: this.digitDecString: 'no'}}</mat-footer-cell>
              </ng-container>
              <ng-container matColumnDef="totBudget">
                <mat-header-cell *matHeaderCellDef> budsjett </mat-header-cell>
                <mat-cell *matCellDef="let branch"> {{branch.totBudget | number: this.digitDecString: 'no'}} </mat-cell>
                <mat-footer-cell *matFooterCellDef> {{this.sumTotBudget | number: this.digitDecString: 'no'}}</mat-footer-cell>
              </ng-container>
              <ng-container matColumnDef="inPOfTotalBudget">
                <mat-header-cell *matHeaderCellDef> I % av budsjett </mat-header-cell>
                <mat-cell *matCellDef="let branch"> {{branch.inPOfTotalBudget.toFixed(2)}} </mat-cell>
                <mat-footer-cell *matFooterCellDef> {{this.sumInPOfTotalBudget.toFixed(2)}}</mat-footer-cell>
              </ng-container>
              <ng-container matColumnDef="totRevisedBudget">
                <mat-header-cell *matHeaderCellDef> Revidert Budsjett </mat-header-cell>
                <mat-cell *matCellDef="let branch"> {{branch.totRevisedBudget | number: this.digitDecString: 'no'}} </mat-cell>
                <mat-footer-cell *matFooterCellDef> {{this.sumTotRevisedBudget | number: this.digitDecString: 'no'}}</mat-footer-cell>
              </ng-container>
              <ng-container matColumnDef="inPOfTotalRevisedBudget">
                <mat-header-cell *matHeaderCellDef> I % av revidert Budsjett </mat-header-cell>
                <mat-cell *matCellDef="let branch"> {{branch.inPOfTotalRevisedBudget.toFixed(2)}} </mat-cell>
                <mat-footer-cell *matFooterCellDef> {{this.sumInPOfTotalRevisedBudget.toFixed(2)}}</mat-footer-cell>
              </ng-container>
              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row >
              <mat-row *matRowDef="let row; columns: displayedColumns;" class = "element-row"></mat-row>
              <mat-footer-row *matFooterRowDef="displayedColumns"></mat-footer-row>
              <!-- these two are for "clicking".
              <mat-row *matRowDef="let row; columns: displayedColumns;"
                       matRipple
                       class = "element-row"
                       [class.expanded]="expandedElement === row"
                       (click)="setExpanded(row)"></mat-row>
              -->
              <!--
              <mat-row *matRowDef="let row; columns: ['expandedDetail']; when: isExpansionDetailRow"
                       [@detailExpand]="row == expandedElement ? 'expanded' : 'collapsed'"
                       style="overflow: hidden">
              </mat-row>
              -->
            </table>
          </div>
        </div>
        <h2 style="margin-top: 20px" *ngIf="dataSource.data.length>0">Formål:</h2>
        <div class="branchBreakdown" *ngFor="let branch of branches" style="margin-bottom: 20px">
          <app-branch [branch]="branch.account+': '+branch.name" [reasons]="branch.reasons" [decimals]="selectedDec" [showNone]="showNone"></app-branch>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #loadingAnimation>
  <mat-spinner mode="indeterminate" class="centerSpin" diameter="150"></mat-spinner>
</ng-template>
<!-- Shows when data is empty, could be improved a bit. -->
<ng-template #emptyTable>
  <h2>Ingenting funnet.</h2>
  <p>Vennligst velg søke parametre, og klikk submit for å hente en rapport.</p>
</ng-template>
<ng-template #errorMessage>
  <mat-error>Noe gikk galt. Melding fra server: {{this.serverMessage}}</mat-error>
</ng-template>
<div *ngIf="contextMenu.show">
  <app-context-menu></app-context-menu>
</div>
