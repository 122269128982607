import { NgModule } from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {FoReportComponent} from './foReport/fo-report/fo-report.component';
import {AuthGuard} from './auth.guard';
import {UrlErrorComponent} from './errorPages/url-error/url-error.component';
import {AuthErrorComponent} from './errorPages/auth-error/auth-error.component';
import {ContextErrorComponent} from './errorPages/context-error/context-error.component';
import {BudgetReportComponent} from './budget-report/budget-report.component';
import {LoginComponent} from './login/login.component';
import {FaqComponent} from './faq/faq.component';
// These are the "paths" to different componenets.

const routes: Routes = [
  // Context is the given company for FO to initially work with, like FO Agder etc.
  // example path: "xxxSomeurl.no/foReport/{{contextId}}?authToken for now. Might want to change auth logic to something differerent.
  // This top one is basically "on default path, just redirect them to FO report, with Fellesorganisasjonen (FO) as given context."
  // Redirects needs to be "before" the others.
  { path: '', redirectTo: 'login', pathMatch: 'full'},
  { path: 'login', component: LoginComponent},
  { path: 'foReport', component: FoReportComponent, canActivate: [AuthGuard] },
  { path: 'budgetReport', component: BudgetReportComponent, canActivate: [AuthGuard]},
  { path: 'faq', component: FaqComponent},
  { path: 'unauthorized', component: AuthErrorComponent},
  { path: 'contextError', component: ContextErrorComponent},
  { path: '**', redirectTo: 'login', pathMatch: 'full'}
];


@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  providers: [AuthGuard],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
