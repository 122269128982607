<div class="sideMenu">
  <form [formGroup]="form" class="form">
      <mat-form-field class="field-selectYear"  appearance="fill">
        <mat-label>Fra år</mat-label>
        <input matInput type="text" formControlName="fromYear">
      </mat-form-field>
      <div *ngIf="this.fromYear.invalid && this.fromYear.dirty">
        <!-- There are many ways to implement "error" messages,
        however try to avoid getting several mat-errors as displayed on the same form-field at the same time-->
        <mat-error *ngIf="this.fromYear.errors.required; else fromYearTypeError">Vennligst Angi ett start år.</mat-error>
        <ng-template #fromYearTypeError>
          <mat-error>Formate må være YYYY som f.eks: 2020</mat-error>
        </ng-template>
      </div>
    <mat-form-field class="field-selectYear"  appearance="fill">
      <mat-label>Til år</mat-label>
      <input matInput type="text" formControlName="toYear">
    </mat-form-field>
    <div *ngIf="this.toYear.invalid && this.fromYear.dirty">
      <!-- There are many ways to implement "error" messages, and you can make it simpler than this.
      However try to avoid getting several mat-errors as displayed on the same form-field at the same time-->
      <mat-error *ngIf="this.toYear.errors.required; else toYearTypeError">Vennligst Angi ett start år.</mat-error>
      <ng-template #toYearTypeError>
        <mat-error>Formate må være YYYY som f.eks: 2020</mat-error>
      </ng-template>
    </div>
    <mat-form-field class="field-selectPeriod"  appearance="fill">
      <mat-label>Start periode</mat-label>
      <mat-select [compareWith]="periodComparator" formControlName="startPeriod">
        <mat-option *ngFor="let period of periods" [value]="period">{{period}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="field-selectPeriod"  appearance="fill">
      <mat-label>Slutt periode</mat-label>
      <mat-select [compareWith]="periodComparator" formControlName="endPeriod">
        <mat-option *ngFor="let period of periods" [value]="period">{{period}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="field-fraTil"  appearance="fill">
      <mat-label>Fra konto</mat-label>
      <input matInput type="text" formControlName="fromAccount">
      <div *ngIf="this.fromAccount.invalid && this.fromAccount.dirty">
        <mat-error *ngIf="this.fromAccount.errors.required; else fromAccountTypeError">Vennligst Angi ett konto nr.</mat-error>
        <ng-template #fromAccountTypeError>
          <mat-error *ngIf="this.fromAccount">Feil format: vennligst bruk bare tall, og 4 siffer.</mat-error>
        </ng-template>
      </div>
    </mat-form-field>
    <mat-form-field  appearance="fill">
      <mat-label>Til konto</mat-label>
      <input matInput type="text" formControlName="toAccount">
        <div *ngIf="this.toAccount.invalid && this.toAccount.dirty">
        <mat-error *ngIf="this.toAccount.errors.required; else toAccountTypeError">Vennligst Angi ett konto nr.</mat-error>
        <ng-template #toAccountTypeError>
          <mat-error>Feil format: vennligst bruk bare tall, og 4 siffer.</mat-error>
        </ng-template>
      </div>
    </mat-form-field>
    <!-- Uncomment this if you want toggle on buffer. Warning too long periods(transaction wise) could make the server just return 504 from taking too long.
    <mat-slide-toggle  style="margin-bottom: 20px" [checked]="!forceAll" (change)="toggleForceAll($event)">Bruk buffer.</mat-slide-toggle>
     -->
    <!-- List all contexts here, then "highlight" current one based on contextId.
    for now just a normal select, but could be a "expanded scroll box" if wanted.-->
    <!--
    <mat-form-field class="contexts"  appearance="fill">
      <mat-label>Firma</mat-label>
      <mat-select [compareWith]="contextComparator" formControlName="context">
        <mat-option *ngFor="let con of contexts" [value]="con">{{con.name}}</mat-option>
      </mat-select>
    </mat-form-field>transi
    -->
  </form>
  <button mat-raised-button color="primary" type="submit" (click)="onSubmit()" [disabled]="form.invalid">Submit</button>
</div>
