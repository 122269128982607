<div fxLayoutAlign="center center" style="text-align: center; width: 100%; height: 90%">
  <div *ngIf="!loading; else loadingAnimation">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div fxLayout="column">
        <h2>Vennligst logg inn</h2>
      </div>
      <div fxLayout="column">
        <mat-form-field appearance="fill">
          <mat-label>Brukernavn</mat-label>
          <input matInput type="text" formControlName="username">
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Passord</mat-label>
          <input matInput type="text" formControlName="password"  [type]="hide ? 'password' : 'text'"  autocomplete="on">
          <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </mat-form-field>
      </div>
      <div fxLayout="column">
        <mat-error *ngIf="serverMessage != ''">{{this.serverMessage}}</mat-error>
      </div>
      <div fxLayout="column">
        <button mat-raised-button color="primary" type="submit" [disabled]="form.invalid">Logg inn</button>
      </div>
    </form>
  </div>
  <ng-template #loadingAnimation>
    <mat-spinner style="margin:0 auto;"></mat-spinner>
  </ng-template>
</div>
