import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
// Authorization logic needs to be stored here in a way that it is only true when an authorized used is here.
// Note this is only used to prevent regular users from routing to other parts of the web app.
// It is not in itself a proper security measure, this is the job of the API to handle through for example token generation which.
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // If loggedInfo is stored, then allow them to route. This should be good anouth for now.
    if (localStorage.getItem('loggedInfo')) {
      return true;
    } else {
      this.router.navigateByUrl('login');
      return false;
    }
  }
}
