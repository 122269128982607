<div>
  <div class="sec1 sideMenu" fxFlex="210px">
    <app-budget-menu (formValues)="menuSubmit($event)"></app-budget-menu>
  </div>
  <div fxFlex="calc(100% - 210px)">
    <div class="sec2" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center center">
      <div  class="infoCard">
        <mat-card class="infoCardFill">
          <mat-card-title>
            <mat-icon mat-card-avatar class="material-icons-two-tone">search</mat-icon>
            <mat-card-title>Brukte felt for siste søk</mat-card-title>
          </mat-card-title>
          <mat-card-content *ngIf="searched" class="infoCardContent">
            <ul class="infoCardList">
              <li>år: {{this.year}}</li>
              <li>Budsjett hovedbok: {{this.ledger}}</li>
              <li>Revidert budsjett hovedbok: {{this.revisedLedger}}</li>
            </ul>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="adjustMenu">
        <mat-card class="adjustMenuFill">
          <mat-card-title>
            <mat-icon mat-card-avatar class="material-icons-two-tone">settings</mat-icon>
            <mat-card-title>Juster visning</mat-card-title>
          </mat-card-title>
          <mat-card-content>
            <mat-form-field appearance="outline">
              <mat-label>Velg antall desimaler</mat-label>
              <mat-select [(ngModel)]="selectedDec" (valueChange)="changeDecText($event)">
                <mat-option *ngFor="let dec of decimals" [value]="dec">{{dec}}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-slide-toggle  style="margin-left: 20px" [checked]="show0s" (change)="toggleHide($event)">Vis formål med 0.</mat-slide-toggle>
            <mat-slide-toggle  style="margin-left: 20px" [checked]="showNone" (change)="toggleNone($event)">Vis "Ingen".</mat-slide-toggle>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <div class="branches" *ngIf="!loading; else loadingAnimation" fxLayoutAlign="center center">
        <div *ngIf="!hadError; else errorMessage">
          <div *ngIf="dataSource.data.length>0; else emptyTable">
            <h2>Budsjett rapport: <button mat-raised-button color="primary" [disabled]="tableForm.invalid || hadError || this.branches.length === 0" (click)="onSubmit()" style="margin-bottom: 5px">Lagre budsjett</button></h2>
            <div class="allBranchesSummary">
              <form [formGroup]="tableForm" *ngIf="branches.length>0">
                <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                  <ng-container matColumnDef="accountNumber">
                    <mat-header-cell *matHeaderCellDef> Avd. </mat-header-cell>
                    <mat-cell *matCellDef="let row let i = index">{{row.branchNr}}</mat-cell>
                    <mat-footer-cell  *matFooterCellDef></mat-footer-cell>
                  </ng-container>
                  <ng-container matColumnDef="accountDesc">
                    <mat-header-cell *matHeaderCellDef> Tekst </mat-header-cell>
                    <mat-cell *matCellDef="let row let i = index">{{row.description}}</mat-cell>
                    <mat-footer-cell  *matFooterCellDef> SUM: </mat-footer-cell>
                  </ng-container>
                  <ng-container matColumnDef="totBudget">
                    <mat-header-cell *matHeaderCellDef> budsjett </mat-header-cell>
                    <mat-cell *matCellDef="let row let i = index">{{row.totBudget  | number: this.digitDecString: 'no'}}</mat-cell>
                    <mat-footer-cell  *matFooterCellDef> {{this.sumTotalBudget  | number: this.digitDecString: 'no'}}</mat-footer-cell>
                  </ng-container>
                  <ng-container matColumnDef="totRevisedBudget">
                    <mat-header-cell *matHeaderCellDef> Revidert Budsjett </mat-header-cell>
                    <mat-cell *matCellDef="let row let i = index">{{row.totRevisedBudget  | number: this.digitDecString: 'no'}}</mat-cell>
                    <mat-footer-cell  *matFooterCellDef> {{this.sumTotalRevisedBudget  | number: this.digitDecString: 'no'}}</mat-footer-cell>
                  </ng-container>
                  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                  <mat-footer-row *matFooterRowDef="displayedColumns"></mat-footer-row>
                </table>
              </form>
            </div>
          </div>
          <h2 style="margin-top: 20px" *ngIf="dataSource.data.length>0">Formål:</h2>
          <div class="branchBreakdown" *ngFor="let branch of branches" style="margin-bottom: 20px;">
            <app-sub-budget [branch]="branch.branchNr+': '+branch.description" [reasons]="branch.reasons" [tableForm]="tableForm" [showNone]="showNone" (edit)="onEdit($event)"></app-sub-budget>
          </div>
        </div>
      </div>
    </div>
  </div>
<ng-template #loadingAnimation>
  <mat-spinner diameter="150"  class="centerSpin"></mat-spinner>
</ng-template>
<!-- Shows when data is empty, could be improved a bit. -->
<ng-template #emptyTable>
  <h2>Ingenting funnet.</h2>
  <p>Vennligst velg søke parametre, og klikk submit for å hente en rapport.</p>
</ng-template>
<ng-template #errorMessage>
  <mat-error>En feil skjedde. Melding fra server: {{this.serverMessage}}</mat-error>
</ng-template>
