import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {Reason} from '../models/Reason';

@Injectable({
  providedIn: 'root'
})
// simple handling of wether to show a rightclick menu or not(this only really deals with custom menus).
export class ContextMenuService {
  private contextSub: BehaviorSubject<Menu>;
  // approximation for now, consider making dynamic.
  popupWidth = 120;
  popupHeight = 60;
  constructor() {
    this.contextSub = new BehaviorSubject(new Menu(0, 0, undefined, false, DataType.None));
  }
  public showContextMenu(x: number, y: number, data: Reason, type: DataType): void {
    this.contextSub.next(new Menu(x, y, data, true, type));
  }
  public closeMenu() {
    this.contextSub.next(new Menu(0, 0, undefined, false, DataType.None));
  }
  public getContextMenu() {
    return this.contextSub.getValue();
  }

  getContextMenuSub(): BehaviorSubject<Menu> {
    return this.contextSub;
  }
  getContextMenuObs(): Observable<Menu> {
    return this.contextSub.asObservable();
  }
}
// Extend this for other types.
export enum DataType {
  Reason,
  None
}
export class Menu {
  public contextMenuX = 0;
  public contextMenuY = 0;
  public contextData = undefined;
  public show = false;
  public type: DataType;
  constructor(contextMenuX: number, contextMenuY: number, contextData: any, show: boolean, type: DataType) {
    this.contextMenuX = contextMenuX;
    this.contextMenuY = contextMenuY;
    this.contextData = contextData;
    this.show = show;
    this.type = type;
  }
}
