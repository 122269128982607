import { Injectable } from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class CommonMessagesService {
  // Used to display messages.
  constructor(private snackBar: MatSnackBar) { }
  // Needs to hit "action" button for the message to go away.
  public displayUntillClosed(message: string): void {
    this.snackBar.open(message, 'Lukk');
  }
  // Will automatically disappear after set duration.
  public displayMessage(message: string): void {
    this.snackBar.open(message, 'Lukk', {
      duration: 3000,
    });
  }
  // change these if you want errors to be displayed differently when they happen.
  public displayError(message: string): void {
    alert(message);
  }
  public displayErrorFromError(error) {
    alert(error);
  }
}
