export class BudgetReason {
  financialYear: string;
  released: boolean;
  releasedAmount: number;
  // should be "account number" is needed when updating.
  account: string;
  accountDesc: string;
  subAccountId: string;
  subAccountDescription;
  description: string;
  amount: number;
  distributedAmount: number;
  periods: Period[];
  revisedPeriods: Period[];
  branchNumber: string;
  branchName: string;
  revisedBudget: number;
  // Needed for when updating budgets
  subaccountSegments: [];
  // used to determine PUT vs POST later.
  revisedExists: boolean;
  // This is just a quick fix to identify who to "send". It is merely to reduce submit times due to unneeded budget sends to visma api.
  changed: boolean;
  revisedChanged: boolean;
  constructor(financialYear: string, released: boolean, releasedAmount: number, account: string, accountDesc: string, subAccountId: string,
              subAccountDescription, description: string, amount: number, distributedAmount: number, periods: Period[],
              revisedBudget: number, branchNumber: string, branchName: string, revisedPeriods: Period[], subaccountSegments: [],
              revisedExists: boolean) {
    this.financialYear = financialYear; this.released = released; this.releasedAmount = releasedAmount;
    this.account = account; this.accountDesc = accountDesc; this.subAccountId = subAccountId;
    this.subAccountDescription = subAccountDescription; this.description = description; this.amount = amount;
    this.distributedAmount = distributedAmount; this.periods = periods; this.revisedBudget = revisedBudget;
    this.branchNumber = branchNumber; this.branchName = branchName; this.subaccountSegments = subaccountSegments;
    this.revisedExists = revisedExists; this.changed = false; this.revisedPeriods = revisedPeriods; this.revisedChanged = false;
    if (this.revisedBudget === undefined) {
      this.revisedBudget = 0.0;
    }
  }
  // Do not actually change the value of subAccountId, it needs to be used later if budgets are submitted for updates.
  getSubDisplay(): string {
    return this.subAccountId.substring(2, 5);
  }
  // Will distribute the amount over 12 periods. If this returns false, it means the distribution failed its check.
  updatePeriods(): boolean {
    if (this.revisedPeriods === undefined) {
      this.revisedPeriods = this.periods.map(p => Object.assign({}, p));
      // Ensure if this is triggered to also specify that revised is new.
      // Note if revised does exist in visma, then this will cause update error later.
      this.revisedPeriods.forEach(p => p.exists = false);
    }
    const amountPer = this.divide(this.amount, 12);
    const rem = this.calcRemainder(this.amount, 12);
    console.log('Spliting revisedAmount: ' + this.revisedBudget);
    const rAmountPer =  this.divide(this.revisedBudget, 12);
    const rRem = this.calcRemainder(this.revisedBudget, 12);
    // Validate sums
    const clone = this.periods.map(x => Object.assign({}, x));
    const rClone = this.revisedPeriods.map(x => Object.assign({}, x));
    // update arrays.
    console.log(this.amount);
    this.updatePeriodAmounts(clone, amountPer, this.amount);
    console.log(this.revisedBudget);
    this.updatePeriodAmounts(rClone, rAmountPer, this.revisedBudget);
    if (!this.checkPeriods(this.amount, clone)) {
      console.log('Error with periods when updating. Stopping.');
      console.log('amount: ' + this.amount + ', rem: ' + rem, ', per: ' + amountPer);
      return false;
    }
    if (!this.checkPeriods(this.revisedBudget, rClone)) {
      console.log('Error with Revised periods when updating. Stopping.');
      console.log('amount: ' + this.revisedBudget + ', rem: ' + rRem, ', per: ' + rAmountPer);
      return false;
    }
    this.revisedPeriods = rClone;
    this.periods = clone;
    return true;
  }
  updatePeriodAmounts(periods: Period[], amountPer: number, amount: number) {
    console.log('attempting to spread periods...');
    console.log('AmountPer: ' + amountPer);
    let num = 0;
    let sum = 0;
    periods.forEach(p => {
      num ++;
      p.amount = amountPer;
      sum += amountPer;
    });
    console.log('Sum: ' + sum);
    const dif = Math.round((amount - sum) * 100) / 100;
    console.log('Dif gotten: ' + dif);
    if (dif !== 0.0) {
      let rounder = 0.01;
      if (dif < 0.0) {
        rounder = -0.01;
      }
      sum = 0;
      const count = Math.abs(dif * 100);
      let counter = 0;
      periods.forEach(p => {
        let m = amountPer;
        if (counter < count) {
          m = Math.round((m += rounder) * 100) / 100;
          p.amount = m;
          sum += m;
        }
        counter++;
      });
      console.log('new Sum: ' + sum);
    }
    console.log('done');
  }
  divide(amount: number, n: number): number {
    return  Math.round(((amount + Number.EPSILON) / n) * 100) / 100;
  }
  calcRemainder(amount: number, n: number): number {
    return amount % n;
  }
  // Returns false if periods do not add up to the amount assigned of budget/revisedBudget. Should be ran after updatePeriods.
  checkPeriods(amount: number, periods: Period[]): boolean {
    let sum = 0.0;
    periods.forEach(p => sum += p.amount);
    sum = Math.round(sum * 100) / 100;
    if (Math.abs(amount - sum) > 0.001) {
      console.log('Incorrect values found on period check.');
      console.log('Budget amount: ' + amount + ', period amount: ' + sum);
      return false;
    }
    return true;
  }
  // for now only calls checkPeriods, but could be extended to do additional checks if needed.
  validateReasons(): boolean {
    if (!this.checkPeriods(this.amount, this.periods)) {
      return false;
    }
    return this.checkPeriods(this.revisedBudget, this.revisedPeriods);
  }
  swapRevisedToExists() {
    if (this.revisedChanged) {
      this.revisedExists = true;
      this.revisedPeriods.forEach(r => r.exists = true);
    }
  }
  submitReset() {
    this.swapRevisedToExists();
    this.changed = false;
    this.revisedChanged = false;
  }
}
export class Period {
  periodId: string;
  amount: number;
  exists: boolean;
  constructor(periodId: string, amount: number, exists: boolean) {
    this.periodId = periodId; this.amount = amount; this.exists = exists;
  }
}
