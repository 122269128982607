import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {BudgetReason} from '../../models/budgetModels/budgetReason';
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatTable, MatTableDataSource} from '@angular/material/table';


@Component({
  selector: 'app-sub-budget',
  templateUrl: './sub-budget.component.html',
  styleUrls: ['./sub-budget.component.css']
})
export class SubBudgetComponent implements OnInit {
  @Input() reasons: BudgetReason[];
  @Input() branch: string;
  @Input() tableForm: FormGroup;
  @ViewChild('table') table: MatTable<any>;
  @Output() edit: EventEmitter<any> = new EventEmitter<any>();
  dataSource: MatTableDataSource<AbstractControl>;
  // Only really use in oninit.
  @Input() showNone: boolean;
  @Input() digit: string;
  displayedColumns: string[] = ['account', 'name',  'budget', 'revisedBudget'];
  hideAll: boolean;
  branchNumber: string;
  digitDecString: string;
  sumBudget: number;
  sumRevisedBudget: number;
  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    this.digitDecString = this.digit;
    this.branchNumber = this.branch.substring(0, 2);
    this.sortReasons();
    this.setBudgetForm(this.reasons);
    // his.budgets.valueChanges.subscribe(b => console.log(b));
    if (this.reasons.length === 0) {
      this.hideAll = true;
    }
    this.filterReasons(this.showNone);
    this.calculateTotalsSummaries();
  }
  get budgets() {return this.tableForm.get(this.branch); }

  private setBudgetForm(reasons: BudgetReason[]) {
    const budgetAr = this.fb.array([]);
    reasons.forEach(r => {
      budgetAr.push(this.getSingleBudgetForm(r));
    });
    this.tableForm.addControl(this.branchNumber, budgetAr);
    // Adds the array of budgets "form fields" to the table.
    this.dataSource = new MatTableDataSource(this.budgetArray.controls);
  }
  getSingleBudgetForm(r: BudgetReason): FormGroup {
    return this.fb.group({
      // These twos should not be allowed to "change via input". They are actually just to show in the table.
      account: [r.getSubDisplay()],
      name: [r.subAccountDescription],
      // changes can be done on these, so validation should be done for now.
      budget: [r.amount, [Validators.required, Validators.min(0), Validators.pattern(/^((\d+(\.\d*)?)|(\.\d+))$/)]],
      rBudget: [r.revisedBudget, [Validators.required, Validators.min(0), Validators.pattern(/^((\d+(\.\d*)?)|(\.\d+))$/)]],
    });
  }
  get budgetArray() {
    return this.tableForm.get(this.branchNumber) as FormArray;
  }
  sortReasons() {
    this.reasons.sort((r1, r2) => Number(r1.getSubDisplay()) - Number(r2.getSubDisplay()));
  }
  filterReasons(showNone: boolean) {
    if (this.branch.substring(0, 2) === '00' && this.reasons.length !== 0) {
      this.hideAll = !showNone;
    }
  }

  filterReasonsBy0(show0s: boolean) {
    if (!show0s) {
      this.setBudgetForm(this.reasons.filter(r => r.amount !== 0.0));
    } else {
      this.setBudgetForm(this.reasons);
    }
  }
  changeDecText($event: number) {
    this.digitDecString = '1.' + $event + '-' + $event;
  }
  overRideBudget(i: number, $event: any, budget: boolean) {
    const reason: BudgetReason[] = this.reasons.filter(r => r.getSubDisplay() === this.budgetArray.at(i).get('account').value);
    let hadError = false;
    if (reason.length !== 1) {
      hadError = true;
      this.edit.emit({hadError, message: 'Feil ved endring på budsjett, Vennligst kontakt avio support.'});
      return;
    }
    // Do not emit if the form is incorrect, this is to avoid empty strings or non number input to be applied.
    if (this.tableForm.get(this.branchNumber).invalid) {
      return;
    }
    if (budget) {
      reason[0].amount = Number($event.target.value);
      reason[0].changed = true;
    } else {
      reason[0].revisedBudget = Number($event.target.value);
      reason[0].revisedChanged = true;
    }
    if (!hadError) {
      this.calculateTotalsSummaries();
    }
    this.edit.emit({reasons: this.reasons, hadError, branchNr: this.branchNumber});
  }
  calculateTotalsSummaries() {
    let sumBudget = 0.0;
    let sumRevisedBudget = 0.0;
    for (const r of this.dataSource.data) {
      sumBudget += Number(r.get('budget').value);
      sumRevisedBudget += Number(r.get('rBudget').value);
    }
    this.sumBudget  = sumBudget ;
    this.sumRevisedBudget  =  sumRevisedBudget ;
  }
}
